.ficon-12PointStar:before { content: "\F505"; }
.ficon-6PointStar:before { content: "\F504"; }
.ficon-AADLogo:before { content: "\ED68"; }
.ficon-Accept:before { content: "\E8FB"; }
.ficon-AcceptMedium:before { content: "\F78C"; }
.ficon-AccessibiltyChecker:before { content: "\F835"; }
.ficon-AccessLogo:before { content: "\ED69"; }
.ficon-AccountActivity:before { content: "\EFF4"; }
.ficon-AccountBrowser:before { content: "\F652"; }
.ficon-AccountManagement:before { content: "\F55C"; }
.ficon-Accounts:before { content: "\E910"; }
.ficon-ActionCenter:before { content: "\E91C"; }
.ficon-ActivateOrders:before { content: "\EFE0"; }
.ficon-ActivityFeed:before { content: "\F056"; }
.ficon-Add:before { content: "\E710"; }
.ficon-AddBookmark:before { content: "\F5B7"; }
.ficon-AddConnection:before { content: "\F4E1"; }
.ficon-AddEvent:before { content: "\EEB5"; }
.ficon-AddFavorite:before { content: "\F0C8"; }
.ficon-AddFavoriteFill:before { content: "\F0C9"; }
.ficon-AddField:before { content: "\E4C7"; }
.ficon-AddFriend:before { content: "\E8FA"; }
.ficon-AddGroup:before { content: "\EE3D"; }
.ficon-AddHome:before { content: "\F17B"; }
.ficon-AddIn:before { content: "\F775"; }
.ficon-AddLink:before { content: "\E35E"; }
.ficon-AddMedium:before { content: "\ECA1"; }
.ficon-AddMultiple:before { content: "\E9C5"; }
.ficon-AddNotes:before { content: "\EAE3"; }
.ficon-AddOnlineMeeting:before { content: "\ED8E"; }
.ficon-AddPhone:before { content: "\ED96"; }
.ficon-AddReaction:before { content: "\F85D"; }
.ficon-AddSpaceAfter:before { content: "\E3DF"; }
.ficon-AddSpaceBefore:before { content: "\E3DE"; }
.ficon-AddTable:before { content: "\E4C6"; }
.ficon-AddTo:before { content: "\ECC8"; }
.ficon-AddToShoppingList:before { content: "\EA9A"; }
.ficon-AddWork:before { content: "\F17C"; }
.ficon-Admin:before { content: "\E7EF"; }
.ficon-AdminALogo32:before { content: "\F4BA"; }
.ficon-AdminALogoFill32:before { content: "\F4BB"; }
.ficon-AdminALogoInverse32:before { content: "\ED6A"; }
.ficon-AdminCLogoInverse32:before { content: "\ED6B"; }
.ficon-AdminDLogoInverse32:before { content: "\ED6C"; }
.ficon-AdminELogoInverse32:before { content: "\ED6D"; }
.ficon-AdminLLogoInverse32:before { content: "\ED6E"; }
.ficon-AdminMLogoInverse32:before { content: "\ED6F"; }
.ficon-AdminOLogoInverse32:before { content: "\ED70"; }
.ficon-AdminPLogoInverse32:before { content: "\ED71"; }
.ficon-AdminSLogoInverse32:before { content: "\ED72"; }
.ficon-AdminYLogoInverse32:before { content: "\ED73"; }
.ficon-Airplane:before { content: "\E709"; }
.ficon-AirplaneSolid:before { content: "\EB4C"; }
.ficon-AirTickets:before { content: "\EF7A"; }
.ficon-AlarmClock:before { content: "\E919"; }
.ficon-Album:before { content: "\E7AB"; }
.ficon-AlbumRemove:before { content: "\EC62"; }
.ficon-AlertSettings:before { content: "\F8B6"; }
.ficon-AlertSolid:before { content: "\F331"; }
.ficon-AlignCenter:before { content: "\E8E3"; }
.ficon-AlignHorizontalCenter:before { content: "\F4F4"; }
.ficon-AlignHorizontalLeft:before { content: "\F4F3"; }
.ficon-AlignHorizontalRight:before { content: "\F4F5"; }
.ficon-AlignJustify:before { content: "\F51E"; }
.ficon-AlignLeft:before { content: "\E8E4"; }
.ficon-AlignRight:before { content: "\E8E2"; }
.ficon-AlignVerticalBottom:before { content: "\F4F8"; }
.ficon-AlignVerticalCenter:before { content: "\F4F7"; }
.ficon-AlignVerticalTop:before { content: "\F4F6"; }
.ficon-AllApps:before { content: "\E71D"; }
.ficon-AllAppsMirrored:before { content: "\EA40"; }
.ficon-AllCurrency:before { content: "\EAE4"; }
.ficon-AltText:before { content: "\E397"; }
.ficon-AnalyticsLogo:before { content: "\F1DE"; }
.ficon-AnalyticsQuery:before { content: "\F1DF"; }
.ficon-AnalyticsReport:before { content: "\F1E1"; }
.ficon-AnalyticsView:before { content: "\F5F1"; }
.ficon-AnchorLock:before { content: "\F511"; }
.ficon-Annotation:before { content: "\E924"; }
.ficon-AppIconDefault:before { content: "\ECAA"; }
.ficon-AppIconDefaultAdd:before { content: "\EFDA"; }
.ficon-AppIconDefaultEdit:before { content: "\EFDC"; }
.ficon-AppIconDefaultList:before { content: "\EFDE"; }
.ficon-AppIconSecure:before { content: "\E657"; }
.ficon-AppsContent:before { content: "\EE54"; }
.ficon-Archive:before { content: "\F03F"; }
.ficon-ArchiveUndo:before { content: "\E3A1"; }
.ficon-AreaChart:before { content: "\E9D2"; }
.ficon-ArrangeBringForward:before { content: "\F509"; }
.ficon-ArrangeBringToFront:before { content: "\F506"; }
.ficon-ArrangeByFrom:before { content: "\F678"; }
.ficon-ArrangeSendBackward:before { content: "\F508"; }
.ficon-ArrangeSendToBack:before { content: "\F507"; }
.ficon-Arrivals:before { content: "\EB34"; }
.ficon-ArrowDownRight8:before { content: "\EED5"; }
.ficon-ArrowDownRightMirrored8:before { content: "\EEF0"; }
.ficon-ArrowTallDownLeft:before { content: "\F2BF"; }
.ficon-ArrowTallDownRight:before { content: "\F2C0"; }
.ficon-ArrowTallUpLeft:before { content: "\F2BD"; }
.ficon-ArrowTallUpRight:before { content: "\F2BE"; }
.ficon-ArrowUpRight:before { content: "\F069"; }
.ficon-ArrowUpRight8:before { content: "\EED4"; }
.ficon-ArrowUpRightMirrored8:before { content: "\EEEF"; }
.ficon-Articles:before { content: "\EAC1"; }
.ficon-Ascending:before { content: "\EDC0"; }
.ficon-AspectRatio:before { content: "\E799"; }
.ficon-AssessmentGroup:before { content: "\F31A"; }
.ficon-AssessmentGroupTemplate:before { content: "\F2B1"; }
.ficon-AssetLibrary:before { content: "\EEB6"; }
.ficon-Assign:before { content: "\E9D3"; }
.ficon-AssignPolicy:before { content: "\E461"; }
.ficon-Asterisk:before { content: "\EA38"; }
.ficon-AsteriskSolid:before { content: "\F34D"; }
.ficon-ATPLogo:before { content: "\EF85"; }
.ficon-Attach:before { content: "\E723"; }
.ficon-AustralianRules:before { content: "\EE70"; }
.ficon-AuthenticatorApp:before { content: "\F6B1"; }
.ficon-AutoDeploySettings:before { content: "\F3FA"; }
.ficon-AutoEnhanceOff:before { content: "\E78E"; }
.ficon-AutoEnhanceOn:before { content: "\E78D"; }
.ficon-AutoFillTemplate:before { content: "\F313"; }
.ficon-AutoFitContents:before { content: "\E3E8"; }
.ficon-AutoFitWindow:before { content: "\E3E9"; }
.ficon-AutoHeight:before { content: "\F512"; }
.ficon-AutomateFlow:before { content: "\E3F5"; }
.ficon-AutoRacing:before { content: "\EB24"; }
.ficon-AwayStatus:before { content: "\EE6A"; }
.ficon-AzureAPIManagement:before { content: "\F37F"; }
.ficon-AzureDataExplorer:before { content: "\E439"; }
.ficon-AzureIcon:before { content: "\EB6A"; }
.ficon-AzureKeyVault:before { content: "\F3B4"; }
.ficon-AzureServiceEndpoint:before { content: "\F380"; }
.ficon-Back:before { content: "\E72B"; }
.ficon-BackgroundColor:before { content: "\F42B"; }
.ficon-Backlog:before { content: "\F2AC"; }
.ficon-BacklogBoard:before { content: "\F444"; }
.ficon-BacklogList:before { content: "\F6BF"; }
.ficon-BackToWindow:before { content: "\E73F"; }
.ficon-Badge:before { content: "\EC1B"; }
.ficon-Balloons:before { content: "\ED7E"; }
.ficon-Bank:before { content: "\E825"; }
.ficon-BankSolid:before { content: "\F34F"; }
.ficon-BarChart4:before { content: "\EAE7"; }
.ficon-BarChartHorizontal:before { content: "\E9EB"; }
.ficon-BarChartVertical:before { content: "\E9EC"; }
.ficon-BarChartVerticalEdit:before { content: "\F89D"; }
.ficon-BarChartVerticalFill:before { content: "\F830"; }
.ficon-BarChartVerticalFilter:before { content: "\F77E"; }
.ficon-BarChartVerticalFilterSolid:before { content: "\F77F"; }
.ficon-Baseball:before { content: "\EB20"; }
.ficon-BeerMug:before { content: "\F49E"; }
.ficon-BIDashboard:before { content: "\F543"; }
.ficon-BidiLtr:before { content: "\E9AA"; }
.ficon-BidiRtl:before { content: "\E9AB"; }
.ficon-Bill:before { content: "\E5FA"; }
.ficon-BingLogo:before { content: "\EB6B"; }
.ficon-BirthdayCake:before { content: "\EF8D"; }
.ficon-BlobStorage:before { content: "\E436"; }
.ficon-BlockContact:before { content: "\E8F8"; }
.ficon-Blocked:before { content: "\E733"; }
.ficon-Blocked12:before { content: "\F62E"; }
.ficon-Blocked2:before { content: "\ECE4"; }
.ficon-Blocked2Solid:before { content: "\F737"; }
.ficon-BlockedSite:before { content: "\E72F"; }
.ficon-BlockedSiteSolid12:before { content: "\F70A"; }
.ficon-BlockedSolid:before { content: "\F531"; }
.ficon-Blog:before { content: "\F22B"; }
.ficon-BlowingSnow:before { content: "\E9C9"; }
.ficon-Blur:before { content: "\F28E"; }
.ficon-Boards:before { content: "\EF68"; }
.ficon-Bold:before { content: "\E8DD"; }
.ficon-BoldBulgarian:before { content: "\E5C5"; }
.ficon-BoldF:before { content: "\E5B5"; }
.ficon-BoldG:before { content: "\E5B3"; }
.ficon-BoldK:before { content: "\E5C6"; }
.ficon-BoldKazakh:before { content: "\E5C9"; }
.ficon-BoldKorean:before { content: "\E5BD"; }
.ficon-BoldN:before { content: "\E5B7"; }
.ficon-BoldP:before { content: "\E5C7"; }
.ficon-BoldRussion:before { content: "\E5B9"; }
.ficon-BoldSerbian:before { content: "\E5CA"; }
.ficon-BoldT:before { content: "\E5C8"; }
.ficon-BookAnswers:before { content: "\F8A4"; }
.ficon-BookingsLogo:before { content: "\EDC7"; }
.ficon-BookmarkReport:before { content: "\F76B"; }
.ficon-Bookmarks:before { content: "\E8A4"; }
.ficon-BookmarksMirrored:before { content: "\EA41"; }
.ficon-BooleanData:before { content: "\E678"; }
.ficon-BorderAll:before { content: "\E5F4"; }
.ficon-BorderDash:before { content: "\F50A"; }
.ficon-BorderDot:before { content: "\F50B"; }
.ficon-BorderInside:before { content: "\E5F3"; }
.ficon-BorderInsideHorizontal:before { content: "\E5F2"; }
.ficon-BorderInsideVertical:before { content: "\E5F1"; }
.ficon-BorderNone:before { content: "\E5F0"; }
.ficon-BoxAdditionSolid:before { content: "\F2D4"; }
.ficon-BoxCheckmarkSolid:before { content: "\F2D7"; }
.ficon-BoxMultiplySolid:before { content: "\F2D5"; }
.ficon-BoxPlaySolid:before { content: "\F2D6"; }
.ficon-BoxSubtractSolid:before { content: "\F2D3"; }
.ficon-BranchCommit:before { content: "\F293"; }
.ficon-BranchCompare:before { content: "\F294"; }
.ficon-BranchFork:before { content: "\F173"; }
.ficon-BranchFork2:before { content: "\F291"; }
.ficon-BranchLocked:before { content: "\F292"; }
.ficon-BranchMerge:before { content: "\F295"; }
.ficon-BranchPullRequest:before { content: "\F296"; }
.ficon-BranchSearch:before { content: "\F297"; }
.ficon-BranchShelveset:before { content: "\F298"; }
.ficon-Breadcrumb:before { content: "\EF8C"; }
.ficon-Breakfast:before { content: "\F49C"; }
.ficon-Brightness:before { content: "\E706"; }
.ficon-Broom:before { content: "\EA99"; }
.ficon-BrowserScreenShot:before { content: "\EBED"; }
.ficon-BrowserTab:before { content: "\F5D7"; }
.ficon-BrowserTabScreenshot:before { content: "\F5D8"; }
.ficon-Brunch:before { content: "\F49D"; }
.ficon-Brush:before { content: "\ECFF"; }
.ficon-BucketColor:before { content: "\F1B6"; }
.ficon-BucketColorFill:before { content: "\F1B7"; }
.ficon-BufferTimeAfter:before { content: "\F0D0"; }
.ficon-BufferTimeBefore:before { content: "\F0CF"; }
.ficon-BufferTimeBoth:before { content: "\F0D1"; }
.ficon-Bug:before { content: "\EBE8"; }
.ficon-BugAction:before { content: "\E358"; }
.ficon-BugBlock:before { content: "\E400"; }
.ficon-BugSolid:before { content: "\F335"; }
.ficon-BugSync:before { content: "\E3FF"; }
.ficon-BugWarning:before { content: "\E357"; }
.ficon-Build:before { content: "\F28F"; }
.ficon-BuildDefinition:before { content: "\F6E9"; }
.ficon-BuildIssue:before { content: "\F319"; }
.ficon-BuildQueue:before { content: "\F24F"; }
.ficon-BuildQueueNew:before { content: "\F250"; }
.ficon-BulkPageBlock:before { content: "\E553"; }
.ficon-BulkUpload:before { content: "\F548"; }
.ficon-BulletedList:before { content: "\E8FD"; }
.ficon-BulletedList2:before { content: "\F2C7"; }
.ficon-BulletedList2Mirrored:before { content: "\F2C8"; }
.ficon-BulletedListBullet:before { content: "\F793"; }
.ficon-BulletedListBulletMirrored:before { content: "\F795"; }
.ficon-BulletedListMirrored:before { content: "\EA42"; }
.ficon-BulletedListText:before { content: "\F792"; }
.ficon-BulletedListTextMirrored:before { content: "\F794"; }
.ficon-BulletedTreeList:before { content: "\F84C"; }
.ficon-Bullseye:before { content: "\F272"; }
.ficon-BullseyeTarget:before { content: "\F5F0"; }
.ficon-BullseyeTargetAdd:before { content: "\E664"; }
.ficon-BullseyeTargetDelete:before { content: "\F6C1"; }
.ficon-BullseyeTargetEdit:before { content: "\E319"; }
.ficon-Bus:before { content: "\E806"; }
.ficon-BusinessCard:before { content: "\E5FB"; }
.ficon-BusinessCenterLogo:before { content: "\F4B2"; }
.ficon-BusinessHoursSign:before { content: "\F310"; }
.ficon-BusinessRule:before { content: "\E562"; }
.ficon-BusSolid:before { content: "\EB47"; }
.ficon-ButtonControl:before { content: "\F6C0"; }
.ficon-Cafe:before { content: "\EC32"; }
.ficon-Cake:before { content: "\ECA4"; }
.ficon-CalculatedTable:before { content: "\E4BE"; }
.ficon-Calculator:before { content: "\E8EF"; }
.ficon-CalculatorAddition:before { content: "\E948"; }
.ficon-CalculatorDelta:before { content: "\E406"; }
.ficon-CalculatorEqualTo:before { content: "\E94E"; }
.ficon-CalculatorGroup:before { content: "\E462"; }
.ficon-CalculatorMultiply:before { content: "\E947"; }
.ficon-CalculatorNotEqualTo:before { content: "\F2D2"; }
.ficon-CalculatorPercentage:before { content: "\E94C"; }
.ficon-CalculatorSubtract:before { content: "\E949"; }
.ficon-Calendar:before { content: "\E787"; }
.ficon-CalendarAgenda:before { content: "\EE9A"; }
.ficon-CalendarDay:before { content: "\E8BF"; }
.ficon-CalendarMirrored:before { content: "\ED28"; }
.ficon-CalendarReply:before { content: "\E8F5"; }
.ficon-CalendarSettings:before { content: "\F558"; }
.ficon-CalendarSettingsMirrored:before { content: "\F559"; }
.ficon-CalendarWeek:before { content: "\E8C0"; }
.ficon-CalendarWorkWeek:before { content: "\EF51"; }
.ficon-CalendarYear:before { content: "\E371"; }
.ficon-Calories:before { content: "\ECAD"; }
.ficon-CaloriesAdd:before { content: "\F172"; }
.ficon-Camera:before { content: "\E722"; }
.ficon-CampaignTemplate:before { content: "\F811"; }
.ficon-Cancel:before { content: "\E711"; }
.ficon-CannedChat:before { content: "\F0F2"; }
.ficon-CanvasAppTemplate32:before { content: "\E5FE"; }
.ficon-Car:before { content: "\E804"; }
.ficon-CareActivity:before { content: "\E549"; }
.ficon-CarePlan:before { content: "\E54A"; }
.ficon-CarePlanTemplate:before { content: "\E61E"; }
.ficon-CaretBottomLeftCenter8:before { content: "\F365"; }
.ficon-CaretBottomLeftSolid8:before { content: "\F121"; }
.ficon-CaretBottomRightCenter8:before { content: "\F364"; }
.ficon-CaretBottomRightSolid8:before { content: "\F122"; }
.ficon-CaretDown8:before { content: "\EDD8"; }
.ficon-CaretDownSolid8:before { content: "\EDDC"; }
.ficon-CaretHollow:before { content: "\E817"; }
.ficon-CaretHollowMirrored:before { content: "\EA45"; }
.ficon-CaretLeft8:before { content: "\EDD5"; }
.ficon-CaretLeftSolid8:before { content: "\EDD9"; }
.ficon-CaretRight:before { content: "\F06B"; }
.ficon-CaretRight8:before { content: "\EDD6"; }
.ficon-CaretRightSolid8:before { content: "\EDDA"; }
.ficon-CaretSolid:before { content: "\E818"; }
.ficon-CaretSolid16:before { content: "\EE62"; }
.ficon-CaretSolidAlt:before { content: "\E483"; }
.ficon-CaretSolidDown:before { content: "\F08E"; }
.ficon-CaretSolidLeft:before { content: "\F08D"; }
.ficon-CaretSolidMirrored:before { content: "\EA46"; }
.ficon-CaretSolidRight:before { content: "\F08F"; }
.ficon-CaretSolidUp:before { content: "\F090"; }
.ficon-CaretTopLeftCenter8:before { content: "\F367"; }
.ficon-CaretTopLeftSolid8:before { content: "\EF54"; }
.ficon-CaretTopRightCenter8:before { content: "\F366"; }
.ficon-CaretTopRightSolid8:before { content: "\EF55"; }
.ficon-CaretUp8:before { content: "\EDD7"; }
.ficon-CaretUpSolid8:before { content: "\EDDB"; }
.ficon-Cat:before { content: "\ED7F"; }
.ficon-CategoryClassification:before { content: "\E48C"; }
.ficon-CC:before { content: "\E7F0"; }
.ficon-CCSolid:before { content: "\E4F4"; }
.ficon-CellPhone:before { content: "\E8EA"; }
.ficon-CellSplitVertical:before { content: "\E66C"; }
.ficon-Certificate:before { content: "\EB95"; }
.ficon-CertifiedDatabase:before { content: "\F5BB"; }
.ficon-ChangeEntitlements:before { content: "\E310"; }
.ficon-Chart:before { content: "\E999"; }
.ficon-CharticulatorArrangePolar:before { content: "\E632"; }
.ficon-CharticulatorArrangePolarAngles:before { content: "\E633"; }
.ficon-CharticulatorBand:before { content: "\E634"; }
.ficon-CharticulatorGuideCoordinator:before { content: "\E635"; }
.ficon-CharticulatorGuideX:before { content: "\E636"; }
.ficon-CharticulatorGuideY:before { content: "\E637"; }
.ficon-CharticulatorLegend:before { content: "\E638"; }
.ficon-CharticulatorLine:before { content: "\E639"; }
.ficon-CharticulatorLineStyleDashed:before { content: "\E63A"; }
.ficon-CharticulatorLineStyleDotted:before { content: "\E63B"; }
.ficon-CharticulatorLinkingData:before { content: "\E63C"; }
.ficon-CharticulatorLinkingSequence:before { content: "\E63D"; }
.ficon-CharticulatorOrderColumn:before { content: "\E63E"; }
.ficon-CharticulatorOrderRow:before { content: "\E63F"; }
.ficon-CharticulatorPlotCartesian:before { content: "\E640"; }
.ficon-CharticulatorPlotCurve:before { content: "\E641"; }
.ficon-CharticulatorPolarCoordinates:before { content: "\E642"; }
.ficon-CharticulatorSpiral:before { content: "\E643"; }
.ficon-CharticulatorStackRadial:before { content: "\E644"; }
.ficon-CharticulatorStackY:before { content: "\E645"; }
.ficon-ChartSeries:before { content: "\F513"; }
.ficon-ChartTemplate:before { content: "\F812"; }
.ficon-ChartXAngle:before { content: "\F514"; }
.ficon-ChartYAngle:before { content: "\F515"; }
.ficon-Chat:before { content: "\E901"; }
.ficon-ChatBot:before { content: "\F08B"; }
.ficon-ChatInviteFriend:before { content: "\ECFE"; }
.ficon-ChatSettings:before { content: "\E600"; }
.ficon-ChatSolid:before { content: "\F344"; }
.ficon-Checkbox:before { content: "\E739"; }
.ficon-CheckboxComposite:before { content: "\E73A"; }
.ficon-CheckboxCompositeReversed:before { content: "\E73D"; }
.ficon-CheckboxFill:before { content: "\E73B"; }
.ficon-CheckboxIndeterminate:before { content: "\E73C"; }
.ficon-CheckboxIndeterminateCombo:before { content: "\F16E"; }
.ficon-CheckedOutByOther12:before { content: "\F630"; }
.ficon-CheckedOutByYou12:before { content: "\F631"; }
.ficon-CheckList:before { content: "\E9D5"; }
.ficon-CheckListCheck:before { content: "\F7A9"; }
.ficon-CheckListCheckMirrored:before { content: "\F7AB"; }
.ficon-CheckListText:before { content: "\F7A8"; }
.ficon-CheckListTextMirrored:before { content: "\F7AA"; }
.ficon-CheckMark:before { content: "\E73E"; }
.ficon-ChevronDown:before { content: "\E70D"; }
.ficon-ChevronDownEnd:before { content: "\F5E4"; }
.ficon-ChevronDownEnd6:before { content: "\F36F"; }
.ficon-ChevronDownMed:before { content: "\E972"; }
.ficon-ChevronDownSmall:before { content: "\E96E"; }
.ficon-ChevronFold10:before { content: "\F36A"; }
.ficon-ChevronLeft:before { content: "\E76B"; }
.ficon-ChevronLeftEnd6:before { content: "\F371"; }
.ficon-ChevronLeftMed:before { content: "\E973"; }
.ficon-ChevronLeftSmall:before { content: "\E96F"; }
.ficon-ChevronRight:before { content: "\E76C"; }
.ficon-ChevronRightEnd6:before { content: "\F372"; }
.ficon-ChevronRightMed:before { content: "\E974"; }
.ficon-ChevronRightSmall:before { content: "\E970"; }
.ficon-ChevronUnfold10:before { content: "\F369"; }
.ficon-ChevronUp:before { content: "\E70E"; }
.ficon-ChevronUpEnd:before { content: "\E55B"; }
.ficon-ChevronUpEnd6:before { content: "\F370"; }
.ficon-ChevronUpMed:before { content: "\E971"; }
.ficon-ChevronUpSmall:before { content: "\E96D"; }
.ficon-Childof:before { content: "\F82D"; }
.ficon-ChoiceColumn:before { content: "\E4AE"; }
.ficon-Chopsticks:before { content: "\F4A2"; }
.ficon-ChromeBack:before { content: "\E830"; }
.ficon-ChromeBackMirrored:before { content: "\EA47"; }
.ficon-ChromeClose:before { content: "\E8BB"; }
.ficon-ChromeFullScreen:before { content: "\E92D"; }
.ficon-ChromeMinimize:before { content: "\E921"; }
.ficon-ChromeRestore:before { content: "\E923"; }
.ficon-CircleAddition:before { content: "\F2E3"; }
.ficon-CircleAdditionSolid:before { content: "\F2E4"; }
.ficon-CircleDollar:before { content: "\EAED"; }
.ficon-CircleFill:before { content: "\EA3B"; }
.ficon-CircleHalfFull:before { content: "\ED9E"; }
.ficon-CirclePause:before { content: "\F2D9"; }
.ficon-CirclePauseSolid:before { content: "\F2D8"; }
.ficon-CirclePlus:before { content: "\EAEE"; }
.ficon-CircleRing:before { content: "\EA3A"; }
.ficon-CircleShape:before { content: "\F1A5"; }
.ficon-CircleShapeSolid:before { content: "\F63C"; }
.ficon-CircleStop:before { content: "\F2DC"; }
.ficon-CircleStopSolid:before { content: "\F2DB"; }
.ficon-CityNext:before { content: "\EC06"; }
.ficon-CityNext2:before { content: "\EC07"; }
.ficon-ClassNotebookLogo16:before { content: "\F488"; }
.ficon-ClassNotebookLogo32:before { content: "\F486"; }
.ficon-ClassNotebookLogoFill16:before { content: "\F489"; }
.ficon-ClassNotebookLogoFill32:before { content: "\F487"; }
.ficon-ClassNotebookLogoInverse:before { content: "\EDC8"; }
.ficon-ClassNotebookLogoInverse16:before { content: "\F48B"; }
.ficon-ClassNotebookLogoInverse32:before { content: "\F48A"; }
.ficon-ClassroomLogo:before { content: "\EF75"; }
.ficon-Clear:before { content: "\E894"; }
.ficon-ClearFilter:before { content: "\EF8F"; }
.ficon-ClearFormatting:before { content: "\EDDD"; }
.ficon-ClearFormattingA:before { content: "\F79D"; }
.ficon-ClearFormattingEraser:before { content: "\F79E"; }
.ficon-ClearNight:before { content: "\E9C2"; }
.ficon-ClearSelection:before { content: "\E8E6"; }
.ficon-ClearSelectionMirrored:before { content: "\EA48"; }
.ficon-Clicked:before { content: "\F268"; }
.ficon-ClinicalImpression:before { content: "\E54B"; }
.ficon-ClipboardList:before { content: "\F0E3"; }
.ficon-ClipboardListAdd:before { content: "\E4EF"; }
.ficon-ClipboardListMirrored:before { content: "\F0E4"; }
.ficon-ClipboardListQuestion:before { content: "\E4F0"; }
.ficon-ClipboardListReply:before { content: "\E4F1"; }
.ficon-ClipboardSolid:before { content: "\F5DC"; }
.ficon-Clock:before { content: "\E917"; }
.ficon-CloneToDesktop:before { content: "\F28C"; }
.ficon-ClosedCaption:before { content: "\EF84"; }
.ficon-ClosePane:before { content: "\E89F"; }
.ficon-ClosePaneMirrored:before { content: "\EA49"; }
.ficon-Cloud:before { content: "\E753"; }
.ficon-CloudAdd:before { content: "\ECA9"; }
.ficon-CloudDownload:before { content: "\EBD3"; }
.ficon-CloudEdit:before { content: "\E4C8"; }
.ficon-CloudFlow:before { content: "\E5EA"; }
.ficon-CloudImportExport:before { content: "\EE55"; }
.ficon-CloudLink:before { content: "\E4C9"; }
.ficon-CloudNotSynced:before { content: "\EC9C"; }
.ficon-CloudPrinter:before { content: "\EDA6"; }
.ficon-CloudSearch:before { content: "\EDE4"; }
.ficon-CloudSecure:before { content: "\E4D5"; }
.ficon-CloudUpload:before { content: "\EC8E"; }
.ficon-CloudWeather:before { content: "\E9BE"; }
.ficon-Cloudy:before { content: "\E9BF"; }
.ficon-Cocktails:before { content: "\EA9D"; }
.ficon-Code:before { content: "\E943"; }
.ficon-CodeEdit:before { content: "\F544"; }
.ficon-Coffee:before { content: "\EAEF"; }
.ficon-CoffeeScript:before { content: "\F2FA"; }
.ficon-CollapseAll:before { content: "\F85A"; }
.ficon-CollapseContent:before { content: "\F165"; }
.ficon-CollapseContentSingle:before { content: "\F166"; }
.ficon-CollapseMenu:before { content: "\EF66"; }
.ficon-CollegeFootball:before { content: "\EB26"; }
.ficon-CollegeHoops:before { content: "\EB25"; }
.ficon-Color:before { content: "\E790"; }
.ficon-ColorSolid:before { content: "\F354"; }
.ficon-Column:before { content: "\E438"; }
.ficon-ColumnFunction:before { content: "\E4C2"; }
.ficon-ColumnLeftTwoThirds:before { content: "\F1D6"; }
.ficon-ColumnLeftTwoThirdsEdit:before { content: "\F324"; }
.ficon-ColumnOptions:before { content: "\F317"; }
.ficon-ColumnQuestion:before { content: "\E4C0"; }
.ficon-ColumnQuestionMirrored:before { content: "\E4C1"; }
.ficon-ColumnRightTwoThirds:before { content: "\F1D7"; }
.ficon-ColumnRightTwoThirdsEdit:before { content: "\F325"; }
.ficon-ColumnSigma:before { content: "\E4BF"; }
.ficon-ColumnVerticalSection:before { content: "\F81E"; }
.ficon-ColumnVerticalSectionEdit:before { content: "\F806"; }
.ficon-Combine:before { content: "\EDBB"; }
.ficon-Combobox:before { content: "\F516"; }
.ficon-CommandPrompt:before { content: "\E756"; }
.ficon-Comment:before { content: "\E90A"; }
.ficon-CommentActive:before { content: "\F804"; }
.ficon-CommentAdd:before { content: "\F2B3"; }
.ficon-CommentNext:before { content: "\F2B4"; }
.ficon-CommentPrevious:before { content: "\F2B5"; }
.ficon-CommentSolid:before { content: "\E30E"; }
.ficon-CommentUrgent:before { content: "\F307"; }
.ficon-Commitments:before { content: "\EC4D"; }
.ficon-CommonDataServiceCDS:before { content: "\E377"; }
.ficon-CommunicationDetails:before { content: "\E4CF"; }
.ficon-CommunicationDetailsMirrored:before { content: "\E4D0"; }
.ficon-Communications:before { content: "\E95A"; }
.ficon-CompanyDirectory:before { content: "\EF0D"; }
.ficon-CompanyDirectoryMirrored:before { content: "\EF2B"; }
.ficon-Compare:before { content: "\F057"; }
.ficon-CompareUneven:before { content: "\E42E"; }
.ficon-CompassNW:before { content: "\E942"; }
.ficon-Completed:before { content: "\E930"; }
.ficon-Completed12:before { content: "\E559"; }
.ficon-CompletedSolid:before { content: "\EC61"; }
.ficon-ComplianceAudit:before { content: "\E369"; }
.ficon-ConfigurationSolid:before { content: "\F334"; }
.ficon-ConfirmEvent:before { content: "\E680"; }
.ficon-ConnectContacts:before { content: "\EFD4"; }
.ficon-ConnectVirtualMachine:before { content: "\EE9D"; }
.ficon-ConstructionCone:before { content: "\E98F"; }
.ficon-ConstructionConeSolid:before { content: "\F339"; }
.ficon-Contact:before { content: "\E77B"; }
.ficon-ContactCard:before { content: "\EEBD"; }
.ficon-ContactCardSettings:before { content: "\F556"; }
.ficon-ContactCardSettingsMirrored:before { content: "\F557"; }
.ficon-ContactHeart:before { content: "\F862"; }
.ficon-ContactInfo:before { content: "\E779"; }
.ficon-ContactInfoMirrored:before { content: "\EA4A"; }
.ficon-ContactLink:before { content: "\F25F"; }
.ficon-ContactList:before { content: "\F7E5"; }
.ficon-ContactLock:before { content: "\F400"; }
.ficon-ContentFeed:before { content: "\E428"; }
.ficon-ContentSettings:before { content: "\F647"; }
.ficon-ContentUnderstandingApp:before { content: "\E4FB"; }
.ficon-ContextMenu:before { content: "\F37C"; }
.ficon-Contrast:before { content: "\E7A1"; }
.ficon-Copy:before { content: "\E8C8"; }
.ficon-CopyEdit:before { content: "\E464"; }
.ficon-CortanaLogoBeckonInner:before { content: "\F4C6"; }
.ficon-CortanaLogoBeckonOuter:before { content: "\F4C7"; }
.ficon-CortanaLogoInner:before { content: "\E832"; }
.ficon-CortanaLogoOuter:before { content: "\E831"; }
.ficon-CortanaLogoReadyInner:before { content: "\F4C8"; }
.ficon-CortanaLogoReadyOuter:before { content: "\F4C9"; }
.ficon-CostContralLedgerAdmin:before { content: "\F208"; }
.ficon-CostControl:before { content: "\F207"; }
.ficon-Cotton:before { content: "\EAF3"; }
.ficon-Count:before { content: "\E9EE"; }
.ficon-Coupon:before { content: "\F7BC"; }
.ficon-CPlusPlus:before { content: "\F2F4"; }
.ficon-CPlusPlusLanguage:before { content: "\F2F3"; }
.ficon-CreateMailRule:before { content: "\F67A"; }
.ficon-CreditCardBill:before { content: "\ECD6"; }
.ficon-Cricket:before { content: "\EB1E"; }
.ficon-CriticalErrorSolid:before { content: "\F5C9"; }
.ficon-CRMLead:before { content: "\EFD6"; }
.ficon-CRMProcesses:before { content: "\EFB1"; }
.ficon-CRMReport:before { content: "\EFFE"; }
.ficon-CRMResourceOptimizationApp32:before { content: "\F6EF"; }
.ficon-CRMServices:before { content: "\EFD2"; }
.ficon-Crop:before { content: "\E7A8"; }
.ficon-Crown:before { content: "\ED01"; }
.ficon-CrownSolid:before { content: "\F336"; }
.ficon-CSharp:before { content: "\F2F0"; }
.ficon-CSharpLanguage:before { content: "\F2EF"; }
.ficon-CSS:before { content: "\EBEF"; }
.ficon-CtrlButton:before { content: "\E4B8"; }
.ficon-CubeShape:before { content: "\F1AA"; }
.ficon-CubeShapeSolid:before { content: "\E421"; }
.ficon-Currency:before { content: "\EAF5"; }
.ficon-CustomActivity:before { content: "\EFF0"; }
.ficon-CustomEntity:before { content: "\EFF7"; }
.ficon-CustomerAssets:before { content: "\F426"; }
.ficon-CustomizeToolbar:before { content: "\F828"; }
.ficon-CustomList:before { content: "\EEBE"; }
.ficon-CustomListMirrored:before { content: "\EEBF"; }
.ficon-Cut:before { content: "\E8C6"; }
.ficon-Cycling:before { content: "\EAC7"; }
.ficon-D365BusinessCentral:before { content: "\F833"; }
.ficon-D365CoreHR:before { content: "\F6BD"; }
.ficon-D365CustomerInsights:before { content: "\F3C8"; }
.ficon-D365CustomerVoiceApp:before { content: "\E4F7"; }
.ficon-D365ProjectOperations:before { content: "\E432"; }
.ficon-D365TalentInsight:before { content: "\F6BC"; }
.ficon-D365TalentLearn:before { content: "\F6BB"; }
.ficon-DashboardAdd:before { content: "\F52D"; }
.ficon-Database:before { content: "\EFC7"; }
.ficon-DatabaseActivity:before { content: "\E670"; }
.ficon-DatabaseBlock:before { content: "\E617"; }
.ficon-DatabaseRefresh:before { content: "\E67F"; }
.ficon-DatabaseSource:before { content: "\E30A"; }
.ficon-DatabaseSwap:before { content: "\E671"; }
.ficon-DatabaseSync:before { content: "\F842"; }
.ficon-DatabaseView:before { content: "\E437"; }
.ficon-DataConnectionLibrary:before { content: "\EEB7"; }
.ficon-DataEnrichment:before { content: "\E4F5"; }
.ficon-DataFlow:before { content: "\E577"; }
.ficon-Dataflows:before { content: "\F7DD"; }
.ficon-DataflowsLink:before { content: "\E366"; }
.ficon-DataManagementSettings:before { content: "\EFC8"; }
.ficon-Dataverse:before { content: "\E659"; }
.ficon-DateTime:before { content: "\EC92"; }
.ficon-DateTime12:before { content: "\F38F"; }
.ficon-DateTime2:before { content: "\EA17"; }
.ficon-DateTimeMirrored:before { content: "\EE93"; }
.ficon-DeactivateOrders:before { content: "\EFE1"; }
.ficon-Decimals:before { content: "\F218"; }
.ficon-DecisionSolid:before { content: "\F350"; }
.ficon-DeclineCall:before { content: "\F405"; }
.ficon-DecreaseIndent:before { content: "\E39B"; }
.ficon-DecreaseIndentArrow:before { content: "\F7A3"; }
.ficon-DecreaseIndentArrowMirrored:before { content: "\F7A7"; }
.ficon-DecreaseIndentMirrored:before { content: "\E39C"; }
.ficon-DecreaseIndentText:before { content: "\F7A2"; }
.ficon-DecreaseIndentTextMirrored:before { content: "\F7A6"; }
.ficon-DefaultRatio:before { content: "\F529"; }
.ficon-DefaultSettings:before { content: "\F648"; }
.ficon-DefectSolid:before { content: "\F449"; }
.ficon-DefenderApp:before { content: "\E83D"; }
.ficon-DefenderBadge12:before { content: "\F0FB"; }
.ficon-DefenderTVM:before { content: "\F6B3"; }
.ficon-Delete:before { content: "\E74D"; }
.ficon-DeleteColumns:before { content: "\F64E"; }
.ficon-DeleteRows:before { content: "\F64F"; }
.ficon-DeleteRowsMirrored:before { content: "\F650"; }
.ficon-DeleteTable:before { content: "\F651"; }
.ficon-DeliveryTruck:before { content: "\EBF4"; }
.ficon-DelveAnalytics:before { content: "\EEEE"; }
.ficon-DelveAnalyticsLogo:before { content: "\EDCA"; }
.ficon-DelveLogo:before { content: "\F280"; }
.ficon-DelveLogoFill:before { content: "\F281"; }
.ficon-DelveLogoInverse:before { content: "\ED76"; }
.ficon-DensityComfy:before { content: "\E4BA"; }
.ficon-DensityDefault:before { content: "\E4B9"; }
.ficon-DependencyAdd:before { content: "\E344"; }
.ficon-DependencyRemove:before { content: "\E345"; }
.ficon-Deploy:before { content: "\F29D"; }
.ficon-Descending:before { content: "\EDC1"; }
.ficon-Design:before { content: "\EB3C"; }
.ficon-DesktopFlow:before { content: "\E4F3"; }
.ficon-DesktopScreenshot:before { content: "\F5D9"; }
.ficon-DeveloperTools:before { content: "\EC7A"; }
.ficon-DeviceBug:before { content: "\E424"; }
.ficon-DeviceOff:before { content: "\E402"; }
.ficon-DeviceRun:before { content: "\E401"; }
.ficon-Devices2:before { content: "\E975"; }
.ficon-Devices3:before { content: "\EA6C"; }
.ficon-Devices4:before { content: "\EB66"; }
.ficon-Diagnostic:before { content: "\E9D9"; }
.ficon-DiagnosticDataBarTooltip:before { content: "\F7DF"; }
.ficon-DiagnosticDataViewerApp:before { content: "\F568"; }
.ficon-Dialpad:before { content: "\E75F"; }
.ficon-Diamond:before { content: "\ED02"; }
.ficon-DiamondSolid:before { content: "\F34C"; }
.ficon-DiamondUser:before { content: "\E4F9"; }
.ficon-Dictionary:before { content: "\E82D"; }
.ficon-DictionaryRemove:before { content: "\F69A"; }
.ficon-DietPlanNotebook:before { content: "\EAC8"; }
.ficon-DiffInline:before { content: "\F309"; }
.ficon-DiffSideBySide:before { content: "\F30A"; }
.ficon-Diploma:before { content: "\F320"; }
.ficon-DisableUpdates:before { content: "\E8D8"; }
.ficon-DisconnectVirtualMachine:before { content: "\F873"; }
.ficon-Dislike:before { content: "\E8E0"; }
.ficon-DislikeSolid:before { content: "\F3C0"; }
.ficon-DistributeDown:before { content: "\F76A"; }
.ficon-DockLeft:before { content: "\E90C"; }
.ficon-DockLeftMirrored:before { content: "\EA4C"; }
.ficon-DockRight:before { content: "\E90D"; }
.ficon-DocLibrary:before { content: "\EEB8"; }
.ficon-DocsLogoInverse:before { content: "\EDCB"; }
.ficon-Document:before { content: "\E8A5"; }
.ficon-DocumentApproval:before { content: "\F28B"; }
.ficon-Documentation:before { content: "\EC17"; }
.ficon-DocumentManagement:before { content: "\EFFC"; }
.ficon-DocumentReply:before { content: "\EF57"; }
.ficon-DocumentSearch:before { content: "\EF6C"; }
.ficon-DocumentSet:before { content: "\EED6"; }
.ficon-DOM:before { content: "\EC8D"; }
.ficon-DonutChart:before { content: "\F368"; }
.ficon-Door:before { content: "\EB75"; }
.ficon-DoubleBookmark:before { content: "\EB8F"; }
.ficon-DoubleChevronDown:before { content: "\EE04"; }
.ficon-DoubleChevronDown12:before { content: "\EE97"; }
.ficon-DoubleChevronDown8:before { content: "\F36B"; }
.ficon-DoubleChevronLeft:before { content: "\EDBE"; }
.ficon-DoubleChevronLeft12:before { content: "\EE98"; }
.ficon-DoubleChevronLeft8:before { content: "\F36D"; }
.ficon-DoubleChevronLeftMed:before { content: "\E991"; }
.ficon-DoubleChevronLeftMedMirrored:before { content: "\EA4D"; }
.ficon-DoubleChevronRight:before { content: "\EDBF"; }
.ficon-DoubleChevronRight12:before { content: "\EE99"; }
.ficon-DoubleChevronRight8:before { content: "\F36E"; }
.ficon-DoubleChevronUp:before { content: "\EDBD"; }
.ficon-DoubleChevronUp12:before { content: "\EE96"; }
.ficon-DoubleChevronUp8:before { content: "\F36C"; }
.ficon-DoubleColumn:before { content: "\F1D4"; }
.ficon-DoubleColumnEdit:before { content: "\F322"; }
.ficon-DoubleDownArrow:before { content: "\F769"; }
.ficon-Down:before { content: "\E74B"; }
.ficon-Download:before { content: "\E896"; }
.ficon-DownloadDocument:before { content: "\F549"; }
.ficon-DragObject:before { content: "\F553"; }
.ficon-DrillDown:before { content: "\F532"; }
.ficon-DrillDownSolid:before { content: "\F533"; }
.ficon-DrillExpand:before { content: "\F534"; }
.ficon-DrillShow:before { content: "\F535"; }
.ficon-DrillThrough:before { content: "\F5B9"; }
.ficon-DriverOff:before { content: "\E3FB"; }
.ficon-DRM:before { content: "\ECA8"; }
.ficon-Drop:before { content: "\EB42"; }
.ficon-Dropdown:before { content: "\EDC5"; }
.ficon-DropShape:before { content: "\F1A8"; }
.ficon-DropShapeSolid:before { content: "\F63F"; }
.ficon-DuplicateRow:before { content: "\F82A"; }
.ficon-Duststorm:before { content: "\E9CD"; }
.ficon-DynamicList:before { content: "\E491"; }
.ficon-Dynamics365Logo:before { content: "\EDCC"; }
.ficon-DynamicSMBLogo:before { content: "\EDCD"; }
.ficon-EaseOfAccess:before { content: "\E776"; }
.ficon-EatDrink:before { content: "\E807"; }
.ficon-EdgeLogo:before { content: "\E3AB"; }
.ficon-EdgeLogo16:before { content: "\E3AA"; }
.ficon-EdgeOldLogo:before { content: "\EC60"; }
.ficon-eDiscovery:before { content: "\E370"; }
.ficon-Edit:before { content: "\E70F"; }
.ficon-EditContact:before { content: "\EFD3"; }
.ficon-EditCreate:before { content: "\F3C9"; }
.ficon-EditEvent:before { content: "\F05B"; }
.ficon-EditListPencil:before { content: "\E61B"; }
.ficon-EditMail:before { content: "\EF61"; }
.ficon-EditMirrored:before { content: "\EB7E"; }
.ficon-EditNote:before { content: "\ED9D"; }
.ficon-EditPhoto:before { content: "\EF77"; }
.ficon-EditSolid12:before { content: "\F4B5"; }
.ficon-EditSolidMirrored12:before { content: "\F4B6"; }
.ficon-EditStyle:before { content: "\EF60"; }
.ficon-EditTable:before { content: "\E4C4"; }
.ficon-Education:before { content: "\E7BE"; }
.ficon-Ellipse:before { content: "\F4FB"; }
.ficon-Embed:before { content: "\ECCE"; }
.ficon-EMI:before { content: "\E731"; }
.ficon-Emoji:before { content: "\E899"; }
.ficon-Emoji2:before { content: "\E76E"; }
.ficon-EmojiDisappointed:before { content: "\EA88"; }
.ficon-EmojiNeutral:before { content: "\EA87"; }
.ficon-EmojiTabSymbols:before { content: "\ED58"; }
.ficon-EmployeeSelfService:before { content: "\EE24"; }
.ficon-EmptyRecycleBin:before { content: "\EF88"; }
.ficon-Encounter:before { content: "\E54C"; }
.ficon-Encryption:before { content: "\F69D"; }
.ficon-EndPointSolid:before { content: "\EB4B"; }
.ficon-EngineeringGroup:before { content: "\F362"; }
.ficon-EntitlementPolicy:before { content: "\E346"; }
.ficon-EntitlementRedemption:before { content: "\E347"; }
.ficon-EntityExtraction:before { content: "\E467"; }
.ficon-EntryDecline:before { content: "\F555"; }
.ficon-EntryView:before { content: "\F554"; }
.ficon-Equalizer:before { content: "\E9E9"; }
.ficon-EraseTool:before { content: "\E75C"; }
.ficon-Error:before { content: "\E783"; }
.ficon-ErrorBadge:before { content: "\EA39"; }
.ficon-ErrorBadge12:before { content: "\E558"; }
.ficon-Event:before { content: "\ECA3"; }
.ficon-Event12:before { content: "\F763"; }
.ficon-EventAccepted:before { content: "\F422"; }
.ficon-EventDate:before { content: "\F059"; }
.ficon-EventDateMissed12:before { content: "\F764"; }
.ficon-EventDeclined:before { content: "\F425"; }
.ficon-EventInfo:before { content: "\ED8B"; }
.ficon-EventTentative:before { content: "\F423"; }
.ficon-EventTentativeMirrored:before { content: "\F424"; }
.ficon-EventToDoLogo:before { content: "\F869"; }
.ficon-ExcelDocument:before { content: "\EF73"; }
.ficon-ExcelLogo:before { content: "\F1E5"; }
.ficon-ExcelLogo16:before { content: "\F397"; }
.ficon-ExcelLogoInverse:before { content: "\EC28"; }
.ficon-ExcelLogoInverse16:before { content: "\F396"; }
.ficon-ExchangeLogo:before { content: "\F284"; }
.ficon-ExchangeLogoInverse:before { content: "\ED78"; }
.ficon-ExerciseTracker:before { content: "\EACC"; }
.ficon-ExpandAll:before { content: "\F859"; }
.ficon-ExpandMenu:before { content: "\EF67"; }
.ficon-ExploreContent:before { content: "\ECCD"; }
.ficon-ExploreContentSingle:before { content: "\F164"; }
.ficon-ExploreData:before { content: "\F5B6"; }
.ficon-Export:before { content: "\EDE1"; }
.ficon-ExportMirrored:before { content: "\EDE2"; }
.ficon-ExpressRouteCircuits:before { content: "\E557"; }
.ficon-ExternalBuild:before { content: "\F445"; }
.ficon-ExternalTFVC:before { content: "\F446"; }
.ficon-ExternalUser:before { content: "\E4CA"; }
.ficon-ExternalXAML:before { content: "\F447"; }
.ficon-Eyedropper:before { content: "\EF3C"; }
.ficon-EyeShadow:before { content: "\F7EB"; }
.ficon-F12DevTools:before { content: "\EBEE"; }
.ficon-FabricAssetLibrary:before { content: "\F09C"; }
.ficon-FabricChannelFolder:before { content: "\E4FA"; }
.ficon-FabricDataConnectionLibrary:before { content: "\F09D"; }
.ficon-FabricDocLibrary:before { content: "\F09E"; }
.ficon-FabricFolder:before { content: "\F0A9"; }
.ficon-FabricFolderConfirm:before { content: "\F7FF"; }
.ficon-FabricFolderFill:before { content: "\F0AA"; }
.ficon-FabricFolderLink:before { content: "\E45C"; }
.ficon-FabricFolderSearch:before { content: "\F0A4"; }
.ficon-FabricFolderUpload:before { content: "\E3AC"; }
.ficon-FabricFormLibrary:before { content: "\F09F"; }
.ficon-FabricFormLibraryMirrored:before { content: "\F0A0"; }
.ficon-FabricMovetoFolder:before { content: "\F0A5"; }
.ficon-FabricNetworkFolder:before { content: "\F5E6"; }
.ficon-FabricNewFolder:before { content: "\F0AB"; }
.ficon-FabricOpenFolderHorizontal:before { content: "\F0A8"; }
.ficon-FabricPictureLibrary:before { content: "\F0AC"; }
.ficon-FabricPublicFolder:before { content: "\F0A3"; }
.ficon-FabricReportLibrary:before { content: "\F0A1"; }
.ficon-FabricReportLibraryMirrored:before { content: "\F0A2"; }
.ficon-FabricSyncFolder:before { content: "\F0A7"; }
.ficon-FabricTextHighlight:before { content: "\F79C"; }
.ficon-FabricTextHighlightComposite:before { content: "\F7DA"; }
.ficon-FabricUnsyncFolder:before { content: "\F0A6"; }
.ficon-FabricUserFolder:before { content: "\F5E5"; }
.ficon-Factory:before { content: "\E60E"; }
.ficon-Family:before { content: "\EBDA"; }
.ficon-FangBody:before { content: "\ECEB"; }
.ficon-FastForward:before { content: "\EB9D"; }
.ficon-FastForwardEightX:before { content: "\E443"; }
.ficon-FastForwardFourX:before { content: "\E442"; }
.ficon-FastForwardOneFiveX:before { content: "\E440"; }
.ficon-FastForwardOneX:before { content: "\E43F"; }
.ficon-FastForwardPointFiveX:before { content: "\E43E"; }
.ficon-FastForwardTwoX:before { content: "\E441"; }
.ficon-FastMode:before { content: "\F19A"; }
.ficon-Favicon:before { content: "\E737"; }
.ficon-FavoriteBlock:before { content: "\E67E"; }
.ficon-FavoriteList:before { content: "\E728"; }
.ficon-FavoriteStar:before { content: "\E734"; }
.ficon-FavoriteStarFill:before { content: "\E735"; }
.ficon-Fax:before { content: "\EF5C"; }
.ficon-Feedback:before { content: "\ED15"; }
.ficon-FeedbackRequestMirroredSolid:before { content: "\F35A"; }
.ficon-FeedbackRequestSolid:before { content: "\F359"; }
.ficon-FeedbackResponseSolid:before { content: "\F35B"; }
.ficon-Ferry:before { content: "\E7E3"; }
.ficon-FerrySolid:before { content: "\EB48"; }
.ficon-FieldChanged:before { content: "\F2C3"; }
.ficon-FieldEmpty:before { content: "\F2C1"; }
.ficon-FieldFilled:before { content: "\F2C2"; }
.ficon-FieldNotChanged:before { content: "\F2C4"; }
.ficon-FieldReadOnly:before { content: "\F442"; }
.ficon-FieldRequired:before { content: "\F443"; }
.ficon-FileASPX:before { content: "\F2E9"; }
.ficon-FileBug:before { content: "\F30D"; }
.ficon-FileCode:before { content: "\F30E"; }
.ficon-FileComment:before { content: "\F30F"; }
.ficon-FileCSS:before { content: "\F2EA"; }
.ficon-FileHTML:before { content: "\F2ED"; }
.ficon-FileImage:before { content: "\F311"; }
.ficon-FileJAVA:before { content: "\F2E8"; }
.ficon-FileLess:before { content: "\F2EC"; }
.ficon-FileOff:before { content: "\E3FC"; }
.ficon-FilePDB:before { content: "\F2E5"; }
.ficon-FileRequest:before { content: "\F789"; }
.ficon-FileSass:before { content: "\F2EB"; }
.ficon-FileSQL:before { content: "\F2E7"; }
.ficon-FileSymlink:before { content: "\F312"; }
.ficon-FileSystem:before { content: "\E433"; }
.ficon-FileTemplate:before { content: "\F2E6"; }
.ficon-FileTypeSolution:before { content: "\F387"; }
.ficon-FileYML:before { content: "\F5DA"; }
.ficon-Filter:before { content: "\E71C"; }
.ficon-FilterAscending:before { content: "\F21A"; }
.ficon-FilterDescending:before { content: "\F21B"; }
.ficon-Filters:before { content: "\E795"; }
.ficon-FilterSettings:before { content: "\F76C"; }
.ficon-FilterSolid:before { content: "\F412"; }
.ficon-FiltersSolid:before { content: "\F353"; }
.ficon-Financial:before { content: "\E7BB"; }
.ficon-FinancialMirroredSolid:before { content: "\F347"; }
.ficon-FinancialSolid:before { content: "\F346"; }
.ficon-Fingerprint:before { content: "\E928"; }
.ficon-FitPage:before { content: "\E9A6"; }
.ficon-FitWidth:before { content: "\E9A7"; }
.ficon-FiveTileGrid:before { content: "\F274"; }
.ficon-FixedAssetManagement:before { content: "\EF93"; }
.ficon-FixedColumnWidth:before { content: "\E3EA"; }
.ficon-Flag:before { content: "\E7C1"; }
.ficon-FlameSolid:before { content: "\F1F3"; }
.ficon-FlashAuto:before { content: "\E95C"; }
.ficon-Flashlight:before { content: "\E754"; }
.ficon-FlashOff:before { content: "\EA6E"; }
.ficon-FlickDown:before { content: "\E935"; }
.ficon-FlickLeft:before { content: "\E937"; }
.ficon-FlickRight:before { content: "\E938"; }
.ficon-FlickUp:before { content: "\E936"; }
.ficon-Flow:before { content: "\EF90"; }
.ficon-FlowChart:before { content: "\E9D4"; }
.ficon-Flower:before { content: "\F54E"; }
.ficon-FlowTemplate:before { content: "\E49C"; }
.ficon-FlowTrigger:before { content: "\E60C"; }
.ficon-FluidLogo:before { content: "\E48A"; }
.ficon-FocalPoint:before { content: "\F277"; }
.ficon-Focus:before { content: "\EA6F"; }
.ficon-FocusView:before { content: "\F1A3"; }
.ficon-Fog:before { content: "\E9CB"; }
.ficon-Folder:before { content: "\E8B7"; }
.ficon-FolderFill:before { content: "\E8D5"; }
.ficon-FolderHorizontal:before { content: "\F12B"; }
.ficon-FolderList:before { content: "\F2CE"; }
.ficon-FolderListMirrored:before { content: "\F2CF"; }
.ficon-FolderOpen:before { content: "\E838"; }
.ficon-FolderQuery:before { content: "\F2CD"; }
.ficon-FolderSearch:before { content: "\EF65"; }
.ficon-FollowUser:before { content: "\EE05"; }
.ficon-Font:before { content: "\E8D2"; }
.ficon-FontColor:before { content: "\E8D3"; }
.ficon-FontColorA:before { content: "\F4EC"; }
.ficon-FontColorKorean:before { content: "\E5BE"; }
.ficon-FontColorSwatch:before { content: "\F4ED"; }
.ficon-FontDecrease:before { content: "\E8E7"; }
.ficon-FontIncrease:before { content: "\E8E8"; }
.ficon-FontSize:before { content: "\E8E9"; }
.ficon-FontSize2:before { content: "\E3C0"; }
.ficon-FontStyleKorean:before { content: "\E5BA"; }
.ficon-Footer:before { content: "\F82E"; }
.ficon-FormatPainter:before { content: "\E3DC"; }
.ficon-FormLibrary:before { content: "\EEB9"; }
.ficon-FormLibraryMirrored:before { content: "\EEBA"; }
.ficon-FormProcessing:before { content: "\E48B"; }
.ficon-Forum:before { content: "\E378"; }
.ficon-Forward:before { content: "\E72A"; }
.ficon-ForwardEvent:before { content: "\ED8C"; }
.ficon-Freezing:before { content: "\E9EF"; }
.ficon-FreezingRain:before { content: "\E475"; }
.ficon-Frigid:before { content: "\E9CA"; }
.ficon-FrontCamera:before { content: "\E96B"; }
.ficon-FSharp:before { content: "\F2F6"; }
.ficon-FSharpLanguage:before { content: "\F2F5"; }
.ficon-FullCircleMask:before { content: "\E91F"; }
.ficon-FullHistory:before { content: "\F31C"; }
.ficon-FullScreen:before { content: "\E740"; }
.ficon-FullView:before { content: "\F1A2"; }
.ficon-FullWidth:before { content: "\F2FE"; }
.ficon-FullWidthEdit:before { content: "\F2FF"; }
.ficon-FunctionalManagerDashboard:before { content: "\F542"; }
.ficon-FunnelChart:before { content: "\E9F1"; }
.ficon-GallatinLogo:before { content: "\F496"; }
.ficon-Game:before { content: "\E7FC"; }
.ficon-Gather:before { content: "\E460"; }
.ficon-Generate:before { content: "\E9DA"; }
.ficon-GenericScan:before { content: "\EE6F"; }
.ficon-GenericScanFilled:before { content: "\F7E3"; }
.ficon-GIF:before { content: "\F4A9"; }
.ficon-Giftbox:before { content: "\EC1F"; }
.ficon-GiftboxOpen:before { content: "\F133"; }
.ficon-GiftBoxSolid:before { content: "\F341"; }
.ficon-GiftCard:before { content: "\EB8E"; }
.ficon-GitGraph:before { content: "\F2CA"; }
.ficon-Glasses:before { content: "\EA16"; }
.ficon-Glimmer:before { content: "\ECF4"; }
.ficon-GlobalNavButton:before { content: "\E700"; }
.ficon-GlobalNavButtonActive:before { content: "\F89F"; }
.ficon-Globe:before { content: "\E774"; }
.ficon-Globe2:before { content: "\F49A"; }
.ficon-GlobeFavorite:before { content: "\EF53"; }
.ficon-Go:before { content: "\E8AD"; }
.ficon-Golf:before { content: "\EB1F"; }
.ficon-GoMirrored:before { content: "\EA4F"; }
.ficon-GoToDashboard:before { content: "\EEED"; }
.ficon-GotoToday:before { content: "\E8D1"; }
.ficon-GraphSymbol:before { content: "\E35D"; }
.ficon-GreetingCard:before { content: "\F54B"; }
.ficon-GridViewLarge:before { content: "\F234"; }
.ficon-GridViewMedium:before { content: "\F233"; }
.ficon-GridViewSmall:before { content: "\F232"; }
.ficon-GripperBarHorizontal:before { content: "\E76F"; }
.ficon-GripperBarVertical:before { content: "\E784"; }
.ficon-GripperDotsVertical:before { content: "\F772"; }
.ficon-GripperTool:before { content: "\E75E"; }
.ficon-Group:before { content: "\E902"; }
.ficon-GroupedAscending:before { content: "\EE67"; }
.ficon-GroupedDescending:before { content: "\EE66"; }
.ficon-GroupedList:before { content: "\EF74"; }
.ficon-GroupList:before { content: "\F168"; }
.ficon-GroupObject:before { content: "\F4F1"; }
.ficon-GroupRemove:before { content: "\E495"; }
.ficon-GUID:before { content: "\F52B"; }
.ficon-Guitar:before { content: "\F49B"; }
.ficon-HailDay:before { content: "\EA00"; }
.ficon-HailNight:before { content: "\EA13"; }
.ficon-HalfAlpha:before { content: "\E97E"; }
.ficon-HalfCircle:before { content: "\F501"; }
.ficon-HandsFree:before { content: "\EAD0"; }
.ficon-Handwriting:before { content: "\E929"; }
.ficon-HardDrive:before { content: "\EDA2"; }
.ficon-HardDriveGroup:before { content: "\F18F"; }
.ficon-HardDriveLock:before { content: "\F55A"; }
.ficon-HardDriveUnlock:before { content: "\F55B"; }
.ficon-HazyDay:before { content: "\E46B"; }
.ficon-HazyNight:before { content: "\E479"; }
.ficon-Header:before { content: "\F82F"; }
.ficon-Header1:before { content: "\EA19"; }
.ficon-Header2:before { content: "\EF36"; }
.ficon-Header3:before { content: "\EF37"; }
.ficon-Header4:before { content: "\EF38"; }
.ficon-Headset:before { content: "\E95B"; }
.ficon-HeadsetSolid:before { content: "\F348"; }
.ficon-Health:before { content: "\E95E"; }
.ficon-HealthRefresh:before { content: "\E3BD"; }
.ficon-HealthSolid:before { content: "\F33F"; }
.ficon-Heart:before { content: "\EB51"; }
.ficon-HeartBroken:before { content: "\EA92"; }
.ficon-HeartFill:before { content: "\EB52"; }
.ficon-Help:before { content: "\E897"; }
.ficon-HelpMirrored:before { content: "\EA51"; }
.ficon-HexaditeInvestigation:before { content: "\E3F8"; }
.ficon-HexaditeInvestigationCancel:before { content: "\E3F9"; }
.ficon-HexaditeInvestigationSemiAuto:before { content: "\E3FA"; }
.ficon-Hexagon:before { content: "\F4FE"; }
.ficon-Hide:before { content: "\ED1A"; }
.ficon-Hide2:before { content: "\EF89"; }
.ficon-Hide3:before { content: "\F6AC"; }
.ficon-HideVisualFilter:before { content: "\F403"; }
.ficon-Highlight:before { content: "\E7E6"; }
.ficon-HighlightMappedShapes:before { content: "\F2A1"; }
.ficon-HintText:before { content: "\F50F"; }
.ficon-HistoricalWeather:before { content: "\EB43"; }
.ficon-History:before { content: "\E81C"; }
.ficon-Home:before { content: "\E80F"; }
.ficon-HomeDropdown:before { content: "\E427"; }
.ficon-HomeGroup:before { content: "\EC26"; }
.ficon-HomeSolid:before { content: "\EA8A"; }
.ficon-HomeVerify:before { content: "\F00E"; }
.ficon-HorizontalDistributeCenter:before { content: "\F4F9"; }
.ficon-HorizontalTabKey:before { content: "\E7FD"; }
.ficon-Hospital:before { content: "\E91D"; }
.ficon-Hot:before { content: "\ECE2"; }
.ficon-Hotel:before { content: "\E824"; }
.ficon-HourGlass:before { content: "\EA03"; }
.ficon-Ice:before { content: "\E473"; }
.ficon-IconSetsFlag:before { content: "\F2A4"; }
.ficon-IDBadge:before { content: "\F427"; }
.ficon-IgnoreConversation:before { content: "\E372"; }
.ficon-ImageCrosshair:before { content: "\F2C9"; }
.ficon-ImageDiff:before { content: "\F30B"; }
.ficon-ImageInAR:before { content: "\E420"; }
.ficon-ImagePixel:before { content: "\F30C"; }
.ficon-ImageSearch:before { content: "\F4E8"; }
.ficon-Import:before { content: "\E8B5"; }
.ficon-ImportAllMirrored:before { content: "\EA53"; }
.ficon-Important:before { content: "\E8C9"; }
.ficon-ImportMirrored:before { content: "\EA52"; }
.ficon-Inbox:before { content: "\F41C"; }
.ficon-InboxActive:before { content: "\E497"; }
.ficon-InboxCheck:before { content: "\EF64"; }
.ficon-IncidentTriangle:before { content: "\E814"; }
.ficon-IncomingCall:before { content: "\E77E"; }
.ficon-IncreaseIndent:before { content: "\E399"; }
.ficon-IncreaseIndentArrow:before { content: "\F7A1"; }
.ficon-IncreaseIndentArrowMirrored:before { content: "\F7A5"; }
.ficon-IncreaseIndentHanging:before { content: "\E39D"; }
.ficon-IncreaseIndentHangingMirrored:before { content: "\E39E"; }
.ficon-IncreaseIndentMirrored:before { content: "\E39A"; }
.ficon-IncreaseIndentText:before { content: "\F7A0"; }
.ficon-IncreaseIndentTextMirrored:before { content: "\F7A4"; }
.ficon-IndentFirstLine:before { content: "\E3DD"; }
.ficon-Info:before { content: "\E946"; }
.ficon-Info12:before { content: "\E55A"; }
.ficon-Info2:before { content: "\EA1F"; }
.ficon-InformationBarriers:before { content: "\F803"; }
.ficon-InfoSolid:before { content: "\F167"; }
.ficon-InkingTool:before { content: "\E76D"; }
.ficon-InputAddress:before { content: "\E41E"; }
.ficon-Insert:before { content: "\F278"; }
.ficon-InsertColumnsLeft:before { content: "\F64A"; }
.ficon-InsertColumnsRight:before { content: "\F64B"; }
.ficon-InsertRowsAbove:before { content: "\F64C"; }
.ficon-InsertRowsBelow:before { content: "\F64D"; }
.ficon-InsertSignatureLine:before { content: "\F677"; }
.ficon-InsertTextBox:before { content: "\EC7D"; }
.ficon-InsertTextBoxKorean:before { content: "\E5D3"; }
.ficon-Insights:before { content: "\E3AF"; }
.ficon-Installation:before { content: "\E311"; }
.ficon-InstallToDrive:before { content: "\F28D"; }
.ficon-IntermittentCloudsDay:before { content: "\E46A"; }
.ficon-IntermittentCloudsNight:before { content: "\E478"; }
.ficon-InternalInvestigation:before { content: "\F854"; }
.ficon-InternetSharing:before { content: "\E704"; }
.ficon-IntersectShape:before { content: "\F8FD"; }
.ficon-Invoice:before { content: "\E9DC"; }
.ficon-IOT:before { content: "\F22C"; }
.ficon-IoTSecure:before { content: "\E4D6"; }
.ficon-IRMForward:before { content: "\F41F"; }
.ficon-IRMForwardMirrored:before { content: "\F420"; }
.ficon-IRMReply:before { content: "\F41D"; }
.ficon-IRMReplyMirrored:before { content: "\F41E"; }
.ficon-IssueSolid:before { content: "\F448"; }
.ficon-IssueTracking:before { content: "\EEC0"; }
.ficon-IssueTrackingMirrored:before { content: "\EEC1"; }
.ficon-Italic:before { content: "\E8DB"; }
.ficon-ItalicC:before { content: "\E5B2"; }
.ficon-ItalicD:before { content: "\E5CE"; }
.ficon-ItalicK:before { content: "\E5B6"; }
.ficon-ItalicKazakh:before { content: "\E5D2"; }
.ficon-ItalicKorean:before { content: "\E5BC"; }
.ficon-ItalicL:before { content: "\E5D1"; }
.ficon-ItalicS:before { content: "\E5CF"; }
.ficon-ItalicT:before { content: "\E5D0"; }
.ficon-JavaScriptLanguage:before { content: "\F2EE"; }
.ficon-JoinOnlineMeeting:before { content: "\ED8F"; }
.ficon-JS:before { content: "\EBF0"; }
.ficon-KaizalaLogo:before { content: "\F492"; }
.ficon-KeyboardClassic:before { content: "\E765"; }
.ficon-KeyPhraseExtraction:before { content: "\E395"; }
.ficon-KnowledgeArticle:before { content: "\F000"; }
.ficon-KnowledgeManagementApp:before { content: "\E4FC"; }
.ficon-Label:before { content: "\E932"; }
.ficon-LadybugSolid:before { content: "\F44A"; }
.ficon-Lamp:before { content: "\EB19"; }
.ficon-LandscapeOrientation:before { content: "\EF6B"; }
.ficon-LaptopSecure:before { content: "\F552"; }
.ficon-LaptopSelected:before { content: "\EC76"; }
.ficon-LargeGrid:before { content: "\EECB"; }
.ficon-LearningApp:before { content: "\E5D8"; }
.ficon-LearningTools:before { content: "\F7DB"; }
.ficon-Leave:before { content: "\F627"; }
.ficon-LeaveUser:before { content: "\E3A8"; }
.ficon-Library:before { content: "\E8F1"; }
.ficon-LibraryAddTo:before { content: "\E60D"; }
.ficon-Lifesaver:before { content: "\EF62"; }
.ficon-LifesaverLock:before { content: "\EF63"; }
.ficon-Light:before { content: "\E793"; }
.ficon-Lightbulb:before { content: "\EA80"; }
.ficon-LightbulbSolid:before { content: "\E681"; }
.ficon-LightningBolt:before { content: "\E945"; }
.ficon-LightningBoltSolid:before { content: "\E45F"; }
.ficon-LightningSecure:before { content: "\E4D3"; }
.ficon-LightSnow:before { content: "\EA02"; }
.ficon-LightWeight:before { content: "\F4EE"; }
.ficon-Like:before { content: "\E8E1"; }
.ficon-LikeSolid:before { content: "\F3BF"; }
.ficon-Line:before { content: "\F4FC"; }
.ficon-LineChart:before { content: "\E9E6"; }
.ficon-LineSpacing:before { content: "\F517"; }
.ficon-LineStyle:before { content: "\F50C"; }
.ficon-LineThickness:before { content: "\F50D"; }
.ficon-Link:before { content: "\E71B"; }
.ficon-Link12:before { content: "\F6E3"; }
.ficon-LinkedDatabase:before { content: "\F779"; }
.ficon-LinkedInLogo:before { content: "\F20A"; }
.ficon-List:before { content: "\EA37"; }
.ficon-ListMirrored:before { content: "\EA55"; }
.ficon-LiveSite:before { content: "\F6A6"; }
.ficon-LocalAdmin:before { content: "\F1FB"; }
.ficon-LocaleLanguage:before { content: "\F2B7"; }
.ficon-Location:before { content: "\E81D"; }
.ficon-LocationCircle:before { content: "\E80E"; }
.ficon-LocationDot:before { content: "\E827"; }
.ficon-LocationFill:before { content: "\E920"; }
.ficon-LocationOutline:before { content: "\F2D0"; }
.ficon-Lock:before { content: "\E72E"; }
.ficon-Lock12:before { content: "\F6E6"; }
.ficon-LockShare:before { content: "\E455"; }
.ficon-LockSolid:before { content: "\E9A2"; }
.ficon-LogRemove:before { content: "\F316"; }
.ficon-LookupEntities:before { content: "\F5B5"; }
.ficon-LowerBrightness:before { content: "\EC8A"; }
.ficon-LowerCase:before { content: "\E5EE"; }
.ficon-LyncLogo:before { content: "\ED79"; }
.ficon-M365InvoicingLogo:before { content: "\F7C1"; }
.ficon-MachineLearning:before { content: "\E3B8"; }
.ficon-Mail:before { content: "\E715"; }
.ficon-MailAlert:before { content: "\ED80"; }
.ficon-MailAttached:before { content: "\F774"; }
.ficon-MailCheck:before { content: "\ED81"; }
.ficon-MailFill:before { content: "\E8A8"; }
.ficon-MailForward:before { content: "\E89C"; }
.ficon-MailForwardMirrored:before { content: "\EA56"; }
.ficon-MailLink:before { content: "\EFAC"; }
.ficon-MailLowImportance:before { content: "\ED82"; }
.ficon-MailOptions:before { content: "\F82C"; }
.ficon-MailPause:before { content: "\ED83"; }
.ficon-MailReminder:before { content: "\F418"; }
.ficon-MailRepeat:before { content: "\ED84"; }
.ficon-MailReply:before { content: "\E8CA"; }
.ficon-MailReplyAll:before { content: "\E8C2"; }
.ficon-MailReplyAllMirrored:before { content: "\EA58"; }
.ficon-MailReplyMirrored:before { content: "\EA57"; }
.ficon-MailSchedule:before { content: "\F72E"; }
.ficon-MailSecure:before { content: "\E4D4"; }
.ficon-MailSolid:before { content: "\F343"; }
.ficon-MailTentative:before { content: "\F416"; }
.ficon-MailTentativeMirrored:before { content: "\F417"; }
.ficon-MailUndelivered:before { content: "\F415"; }
.ficon-ManagerSelfService:before { content: "\EE23"; }
.ficon-Manufacturing:before { content: "\E99C"; }
.ficon-MapDirections:before { content: "\E816"; }
.ficon-MapLayers:before { content: "\E81E"; }
.ficon-MapPin:before { content: "\E707"; }
.ficon-MapPin12:before { content: "\E3AE"; }
.ficon-MapPinSolid:before { content: "\F52E"; }
.ficon-MarkAsProtected:before { content: "\F6AE"; }
.ficon-MarkDownLanguage:before { content: "\F2FB"; }
.ficon-Market:before { content: "\EAFC"; }
.ficon-MarketDown:before { content: "\EF42"; }
.ficon-MasterDatabase:before { content: "\F5BA"; }
.ficon-MaximumValue:before { content: "\F5BC"; }
.ficon-Medal:before { content: "\EE38"; }
.ficon-MedalSolid:before { content: "\F6B9"; }
.ficon-Media:before { content: "\EA69"; }
.ficon-MediaAdd:before { content: "\F510"; }
.ficon-Medical:before { content: "\EAD4"; }
.ficon-MedicalCare:before { content: "\E54D"; }
.ficon-MedicationAdmin:before { content: "\E54E"; }
.ficon-MedicationRequest:before { content: "\E54F"; }
.ficon-Megaphone:before { content: "\E789"; }
.ficon-MegaphoneSolid:before { content: "\F332"; }
.ficon-Memo:before { content: "\E77C"; }
.ficon-Merge:before { content: "\E7D5"; }
.ficon-MergeCase:before { content: "\EFC9"; }
.ficon-MergeDuplicate:before { content: "\F29A"; }
.ficon-Message:before { content: "\E8BD"; }
.ficon-MessageFill:before { content: "\EC70"; }
.ficon-MessageFriendRequest:before { content: "\F055"; }
.ficon-MetricsFailure:before { content: "\E4CE"; }
.ficon-MetricsInstall:before { content: "\E4CD"; }
.ficon-MetricsUsage:before { content: "\E4CC"; }
.ficon-MicOff:before { content: "\EC54"; }
.ficon-MicOff2:before { content: "\F781"; }
.ficon-Microphone:before { content: "\E720"; }
.ficon-MicrosoftStaffhubLogo:before { content: "\F130"; }
.ficon-MicrosoftTranslatorLogo:before { content: "\F782"; }
.ficon-MicrosoftTranslatorLogoBlue:before { content: "\F853"; }
.ficon-MicrosoftTranslatorLogoGreen:before { content: "\F852"; }
.ficon-MiniContract:before { content: "\E93B"; }
.ficon-MiniContractMirrored:before { content: "\EA59"; }
.ficon-MiniExpand:before { content: "\E93A"; }
.ficon-MiniExpandMirrored:before { content: "\EA5A"; }
.ficon-MiniLink:before { content: "\E732"; }
.ficon-MinimumValue:before { content: "\F5BD"; }
.ficon-MobileAngled:before { content: "\E463"; }
.ficon-MobileReport:before { content: "\F18A"; }
.ficon-MobileSelected:before { content: "\EC75"; }
.ficon-ModelAppTemplate32:before { content: "\E5FD"; }
.ficon-ModelingView:before { content: "\F871"; }
.ficon-Money:before { content: "\EAFD"; }
.ficon-More:before { content: "\E712"; }
.ficon-MoreSports:before { content: "\EB22"; }
.ficon-MoreVertical:before { content: "\F2BC"; }
.ficon-MostyClearNight:before { content: "\E476"; }
.ficon-MostyCloudyFlurriesDay:before { content: "\E471"; }
.ficon-MostyCloudyFlurriesNight:before { content: "\E47D"; }
.ficon-MostyCloudyShowersDay:before { content: "\E46D"; }
.ficon-MostyCloudyTStormsDay:before { content: "\E46F"; }
.ficon-MostyCloudyTStormsNight:before { content: "\E47B"; }
.ficon-MostySunnyDay:before { content: "\E468"; }
.ficon-MountainClimbing:before { content: "\F6DB"; }
.ficon-Move:before { content: "\E7C2"; }
.ficon-Movers:before { content: "\EBCD"; }
.ficon-MoveToFolder:before { content: "\E8DE"; }
.ficon-MSListsConnected:before { content: "\E601"; }
.ficon-MSNLogo:before { content: "\EB6C"; }
.ficon-MSNVideos:before { content: "\EB1C"; }
.ficon-MSNVideosSolid:before { content: "\F2DA"; }
.ficon-MSNVolume:before { content: "\EB15"; }
.ficon-MultiSelect:before { content: "\E762"; }
.ficon-MultiSelectMirrored:before { content: "\EA98"; }
.ficon-MusicInCollection:before { content: "\E940"; }
.ficon-MusicInCollectionFill:before { content: "\EA36"; }
.ficon-MusicNote:before { content: "\EC4F"; }
.ficon-MuteChat:before { content: "\F17A"; }
.ficon-MyMoviesTV:before { content: "\EE6C"; }
.ficon-MyNetwork:before { content: "\EC27"; }
.ficon-Nav2DMapView:before { content: "\E800"; }
.ficon-NavigateBack:before { content: "\F2DD"; }
.ficon-NavigateBackMirrored:before { content: "\F2DE"; }
.ficon-NavigateExternalInline:before { content: "\F35F"; }
.ficon-NavigateForward:before { content: "\F2DF"; }
.ficon-NavigateForwardMirrored:before { content: "\F2E0"; }
.ficon-NavigationFlipper:before { content: "\F51D"; }
.ficon-NetworkDeviceScanning:before { content: "\E4F6"; }
.ficon-NetworkTower:before { content: "\EC05"; }
.ficon-NewAnalyticsQuery:before { content: "\F1E0"; }
.ficon-NewFolder:before { content: "\E8F4"; }
.ficon-NewMail:before { content: "\F7EA"; }
.ficon-News:before { content: "\E900"; }
.ficon-NewsSearch:before { content: "\F4E9"; }
.ficon-NewTeamProject:before { content: "\F2B2"; }
.ficon-Next:before { content: "\E893"; }
.ficon-NormalWeight:before { content: "\F4EF"; }
.ficon-NoteForward:before { content: "\ED99"; }
.ficon-NotePinned:before { content: "\ED9A"; }
.ficon-NoteReply:before { content: "\ED98"; }
.ficon-NotExecuted:before { content: "\F440"; }
.ficon-NotImpactedSolid:before { content: "\F441"; }
.ficon-NugetLogo:before { content: "\F44C"; }
.ficon-NUIFace:before { content: "\EB68"; }
.ficon-Number:before { content: "\F691"; }
.ficon-NumberedList:before { content: "\EA1C"; }
.ficon-NumberedListMirrored:before { content: "\E398"; }
.ficon-NumberedListNumber:before { content: "\F797"; }
.ficon-NumberedListNumberMirrored:before { content: "\F799"; }
.ficon-NumberedListText:before { content: "\F796"; }
.ficon-NumberedListTextMirrored:before { content: "\F798"; }
.ficon-NumberField:before { content: "\EDC4"; }
.ficon-NumberSequence:before { content: "\F52A"; }
.ficon-NumberSymbol:before { content: "\F7AC"; }
.ficon-Oauth:before { content: "\E5C3"; }
.ficon-ObjectRecognition:before { content: "\E4EE"; }
.ficon-Octagon:before { content: "\F4FD"; }
.ficon-ODLink:before { content: "\E4BB"; }
.ficon-ODLink12:before { content: "\E4BC"; }
.ficon-ODSharedChannel:before { content: "\E649"; }
.ficon-ODSharedChannel12:before { content: "\E64A"; }
.ficon-OEM:before { content: "\E74C"; }
.ficon-OfficeAddinsLogo:before { content: "\EEC7"; }
.ficon-OfficeAssistantLogo:before { content: "\EDCE"; }
.ficon-OfficeCatchUp:before { content: "\E490"; }
.ficon-OfficeChat:before { content: "\F70F"; }
.ficon-OfficeChatSolid:before { content: "\F710"; }
.ficon-OfficeFormsLogo:before { content: "\F434"; }
.ficon-OfficeFormsLogo16:before { content: "\F436"; }
.ficon-OfficeFormsLogo24:before { content: "\F43B"; }
.ficon-OfficeFormsLogoInverse:before { content: "\EF86"; }
.ficon-OfficeFormsLogoInverse16:before { content: "\F433"; }
.ficon-OfficeFormsLogoInverse24:before { content: "\F43A"; }
.ficon-OfficeLogo:before { content: "\EB6E"; }
.ficon-OfficeStoreLogo:before { content: "\EDCF"; }
.ficon-OfficeVideoLogo:before { content: "\F282"; }
.ficon-OfficeVideoLogoFill:before { content: "\F283"; }
.ficon-OfficeVideoLogoInverse:before { content: "\ED7A"; }
.ficon-OfflineOneDriveParachute:before { content: "\EEC8"; }
.ficon-OfflineOneDriveParachuteDisabled:before { content: "\EEC9"; }
.ficon-OfflineStorage:before { content: "\EC8C"; }
.ficon-OfflineStorageSolid:before { content: "\F34E"; }
.ficon-Onboarding:before { content: "\F3BA"; }
.ficon-OneDriveAdd:before { content: "\EF32"; }
.ficon-OneDriveFolder16:before { content: "\F53B"; }
.ficon-OneDriveLogo:before { content: "\E941"; }
.ficon-OneNoteDocType:before { content: "\F04F"; }
.ficon-OneNoteEduLogoInverse:before { content: "\EDD0"; }
.ficon-OneNoteLogo:before { content: "\F1E7"; }
.ficon-OneNoteLogo16:before { content: "\F39A"; }
.ficon-OneNoteLogoInverse:before { content: "\EC0D"; }
.ficon-OneNoteLogoInverse16:before { content: "\F399"; }
.ficon-OpenEnrollment:before { content: "\EF1C"; }
.ficon-OpenFile:before { content: "\E8E5"; }
.ficon-OpenFolderHorizontal:before { content: "\ED25"; }
.ficon-OpenInNewTab:before { content: "\F6AB"; }
.ficon-OpenInNewWindow:before { content: "\E8A7"; }
.ficon-OpenPane:before { content: "\E8A0"; }
.ficon-OpenPaneMirrored:before { content: "\EA5B"; }
.ficon-OpenSource:before { content: "\EBC2"; }
.ficon-OpenWith:before { content: "\E7AC"; }
.ficon-OpenWithMirrored:before { content: "\EA5C"; }
.ficon-Opportunities:before { content: "\F05F"; }
.ficon-OrderLock:before { content: "\E4CB"; }
.ficon-Org:before { content: "\ECA6"; }
.ficon-Orientation:before { content: "\E8B4"; }
.ficon-Orientation2:before { content: "\F7E1"; }
.ficon-OutlookLogo:before { content: "\F1E9"; }
.ficon-OutlookLogo16:before { content: "\F39D"; }
.ficon-OutlookLogoInverse:before { content: "\EB6D"; }
.ficon-OutlookLogoInverse16:before { content: "\F39C"; }
.ficon-OutlookSpacesBucket:before { content: "\E481"; }
.ficon-OutOfOffice:before { content: "\ED34"; }
.ficon-PAAction:before { content: "\E60B"; }
.ficon-Package:before { content: "\E7B8"; }
.ficon-Packages:before { content: "\F318"; }
.ficon-Padding:before { content: "\F518"; }
.ficon-PaddingBottom:before { content: "\F51A"; }
.ficon-PaddingLeft:before { content: "\F51B"; }
.ficon-PaddingRight:before { content: "\F51C"; }
.ficon-PaddingTop:before { content: "\F519"; }
.ficon-Page:before { content: "\E7C3"; }
.ficon-PageAdd:before { content: "\EA1A"; }
.ficon-PageArrowRight:before { content: "\EFB8"; }
.ficon-PageBlock:before { content: "\EFB5"; }
.ficon-PageCheckedin:before { content: "\F104"; }
.ficon-PageCheckedOut:before { content: "\F02C"; }
.ficon-PageData:before { content: "\E31C"; }
.ficon-PageEdit:before { content: "\EFB6"; }
.ficon-PageHeader:before { content: "\ECEE"; }
.ficon-PageHeaderEdit:before { content: "\E31D"; }
.ficon-PageLeft:before { content: "\E760"; }
.ficon-PageLink:before { content: "\E302"; }
.ficon-PageList:before { content: "\F106"; }
.ficon-PageListFilter:before { content: "\F813"; }
.ficon-PageListMirroredSolid:before { content: "\F33B"; }
.ficon-PageListSolid:before { content: "\F33A"; }
.ficon-PageLock:before { content: "\F43F"; }
.ficon-PagePermission:before { content: "\E618"; }
.ficon-PageRemove:before { content: "\EFBA"; }
.ficon-PageRight:before { content: "\E761"; }
.ficon-PageShared:before { content: "\F02D"; }
.ficon-PageSolid:before { content: "\E729"; }
.ficon-PanoIndicator:before { content: "\E7B0"; }
.ficon-Parachute:before { content: "\F351"; }
.ficon-ParachuteSolid:before { content: "\F352"; }
.ficon-Parameter:before { content: "\F306"; }
.ficon-ParkingLocation:before { content: "\E811"; }
.ficon-ParkingLocationMirrored:before { content: "\EA5E"; }
.ficon-ParkingMirroredSolid:before { content: "\F34B"; }
.ficon-ParkingSolid:before { content: "\F34A"; }
.ficon-PartlyClearNight:before { content: "\E477"; }
.ficon-PartlyCloudyDay:before { content: "\E9C0"; }
.ficon-PartlyCloudyNight:before { content: "\E9C1"; }
.ficon-PartlySunnyDay:before { content: "\E469"; }
.ficon-PartlySunnyFlurriesDay:before { content: "\E472"; }
.ficon-PartlySunnyShowersDay:before { content: "\E46E"; }
.ficon-PartlySunnyShowersNight:before { content: "\E47A"; }
.ficon-PartlySunnyTStormsDay:before { content: "\E470"; }
.ficon-PartlySunnyTStormsNight:before { content: "\E47C"; }
.ficon-PartyLeader:before { content: "\ECA7"; }
.ficon-PassiveAuthentication:before { content: "\F32A"; }
.ficon-PasswordField:before { content: "\F6AA"; }
.ficon-Paste:before { content: "\E77F"; }
.ficon-PasteAsCode:before { content: "\F5D6"; }
.ficon-PasteAsText:before { content: "\F5D5"; }
.ficon-Pause:before { content: "\E769"; }
.ficon-PaymentCard:before { content: "\E8C7"; }
.ficon-PBIAnomaliesMarker:before { content: "\E554"; }
.ficon-PBIAnomaly:before { content: "\E548"; }
.ficon-PBIColumn:before { content: "\E67A"; }
.ficon-PBIConnectPoints:before { content: "\E67B"; }
.ficon-PBIDirectQuery:before { content: "\E4E9"; }
.ficon-PBIDual:before { content: "\E4EA"; }
.ficon-PBIGap:before { content: "\E67C"; }
.ficon-PBIGoalEntry:before { content: "\E615"; }
.ficon-PBIGoalEntryAdd:before { content: "\E616"; }
.ficon-PBIHomeLayoutDefault:before { content: "\E65B"; }
.ficon-PBIHomeLayoutExpanded:before { content: "\E65C"; }
.ficon-PBIImport:before { content: "\E4EB"; }
.ficon-PBILiveConnect:before { content: "\E4EC"; }
.ficon-PBIPerspective:before { content: "\E658"; }
.ficon-PBIReportTemplate:before { content: "\E5EC"; }
.ficon-PBIZero:before { content: "\E67D"; }
.ficon-PC1:before { content: "\E977"; }
.ficon-PDF:before { content: "\EA90"; }
.ficon-PencilReply:before { content: "\EF7B"; }
.ficon-Pentagon:before { content: "\F4FF"; }
.ficon-PenWorkspace:before { content: "\EDC6"; }
.ficon-People:before { content: "\E716"; }
.ficon-PeopleAdd:before { content: "\EA15"; }
.ficon-PeopleAlert:before { content: "\ED93"; }
.ficon-PeopleBlock:before { content: "\ED91"; }
.ficon-PeopleExternalShare:before { content: "\E5EF"; }
.ficon-PeoplePause:before { content: "\ED94"; }
.ficon-PeopleRepeat:before { content: "\ED92"; }
.ficon-Permissions:before { content: "\E8D7"; }
.ficon-PermissionsSolid:before { content: "\F349"; }
.ficon-Personalize:before { content: "\E771"; }
.ficon-Phishing:before { content: "\F679"; }
.ficon-PhishingCampaign:before { content: "\E48D"; }
.ficon-PhishingHook:before { content: "\E48E"; }
.ficon-Phone:before { content: "\E717"; }
.ficon-Photo:before { content: "\E91B"; }
.ficon-Photo2:before { content: "\EB9F"; }
.ficon-Photo2Add:before { content: "\ECAB"; }
.ficon-Photo2Fill:before { content: "\F79F"; }
.ficon-Photo2Remove:before { content: "\ECAC"; }
.ficon-PhotoBlock:before { content: "\E4C3"; }
.ficon-PhotoCollection:before { content: "\E7AA"; }
.ficon-PhotoError:before { content: "\E3F7"; }
.ficon-PhotoVideoMedia:before { content: "\F0B1"; }
.ficon-Picture:before { content: "\E8B9"; }
.ficon-PictureCenter:before { content: "\F522"; }
.ficon-PictureFill:before { content: "\F523"; }
.ficon-PictureLibrary:before { content: "\EEC2"; }
.ficon-PicturePosition:before { content: "\F524"; }
.ficon-PictureStretch:before { content: "\F525"; }
.ficon-PictureTile:before { content: "\F526"; }
.ficon-PieDouble:before { content: "\EB04"; }
.ficon-PieSingle:before { content: "\EB05"; }
.ficon-PieSingleSolid:before { content: "\F530"; }
.ficon-Pill:before { content: "\EACB"; }
.ficon-Pin:before { content: "\E718"; }
.ficon-Pinned:before { content: "\E840"; }
.ficon-PinnedFill:before { content: "\E842"; }
.ficon-PinnedSolid:before { content: "\F676"; }
.ficon-PinSolid12:before { content: "\E352"; }
.ficon-PinSolidOff12:before { content: "\E353"; }
.ficon-PinToTab:before { content: "\E5E5"; }
.ficon-PivotChart:before { content: "\F24C"; }
.ficon-PlainText:before { content: "\F834"; }
.ficon-PlannerLogo:before { content: "\EDD1"; }
.ficon-PlanView:before { content: "\F360"; }
.ficon-Play:before { content: "\E768"; }
.ficon-PlaybackRate1x:before { content: "\EC57"; }
.ficon-PlayerSettings:before { content: "\EF58"; }
.ficon-PlaylistMusic:before { content: "\E93F"; }
.ficon-PlayResume:before { content: "\F2C6"; }
.ficon-PlayReverse:before { content: "\F3E5"; }
.ficon-PlayReverseResume:before { content: "\F3E4"; }
.ficon-PlaySolid:before { content: "\F5B0"; }
.ficon-Plug:before { content: "\F300"; }
.ficon-PlugConnected:before { content: "\F302"; }
.ficon-PlugDisconnected:before { content: "\F303"; }
.ficon-PlugSolid:before { content: "\F301"; }
.ficon-POI:before { content: "\ECAF"; }
.ficon-POISolid:before { content: "\F2D1"; }
.ficon-PollResults:before { content: "\F8A0"; }
.ficon-PopExpand:before { content: "\E49A"; }
.ficon-PortalAppTemplate32:before { content: "\E5FC"; }
.ficon-PostUpdate:before { content: "\E8F3"; }
.ficon-PowerApps:before { content: "\EDD2"; }
.ficon-PowerApps2Logo:before { content: "\F092"; }
.ficon-PowerAppsLogo:before { content: "\F091"; }
.ficon-PowerAppsTemplate:before { content: "\E4AC"; }
.ficon-PowerAutomateLogo:before { content: "\F4B1"; }
.ficon-PowerBILogo:before { content: "\EA1E"; }
.ficon-PowerBILogo16:before { content: "\F790"; }
.ficon-PowerBILogoBackplate16:before { content: "\F791"; }
.ficon-PowerButton:before { content: "\E7E8"; }
.ficon-PowerPointDocument:before { content: "\EF72"; }
.ficon-PowerPointLogo:before { content: "\F1EB"; }
.ficon-PowerPointLogo16:before { content: "\F394"; }
.ficon-PowerPointLogoInverse:before { content: "\EC2A"; }
.ficon-PowerPointLogoInverse16:before { content: "\F393"; }
.ficon-PowerShell:before { content: "\F1FD"; }
.ficon-PowerShell2:before { content: "\F730"; }
.ficon-PowerStandby:before { content: "\E55C"; }
.ficon-PowerVirtualAgentsLogo:before { content: "\E484"; }
.ficon-Precipitation:before { content: "\E9CF"; }
.ficon-PresenceChickletVideo:before { content: "\E979"; }
.ficon-Presentation:before { content: "\F6E4"; }
.ficon-Presentation12:before { content: "\F6E5"; }
.ficon-Preview:before { content: "\E8FF"; }
.ficon-PreviewBelow:before { content: "\E5D5"; }
.ficon-PreviewLink:before { content: "\E8A1"; }
.ficon-PreviewSideBySide:before { content: "\E5D6"; }
.ficon-Previous:before { content: "\E892"; }
.ficon-PrimaryCalendar:before { content: "\F4AE"; }
.ficon-Print:before { content: "\E749"; }
.ficon-PrintfaxPrinterFile:before { content: "\E956"; }
.ficon-Priority:before { content: "\E8D0"; }
.ficon-Process:before { content: "\E9F3"; }
.ficon-ProcessAdvisor:before { content: "\E5E4"; }
.ficon-Processing:before { content: "\E9F5"; }
.ficon-ProcessingCancel:before { content: "\E403"; }
.ficon-ProcessingPause:before { content: "\E405"; }
.ficon-ProcessingRun:before { content: "\E404"; }
.ficon-ProcessMap:before { content: "\E9F6"; }
.ficon-ProcessMetaTask:before { content: "\F290"; }
.ficon-Product:before { content: "\ECDC"; }
.ficon-ProductCatalog:before { content: "\EFE8"; }
.ficon-ProductionFloorManagement:before { content: "\EE29"; }
.ficon-ProductList:before { content: "\E31E"; }
.ficon-ProductRelease:before { content: "\EE2E"; }
.ficon-ProductVariant:before { content: "\EE30"; }
.ficon-ProductWarning:before { content: "\E5C0"; }
.ficon-ProfileSearch:before { content: "\EF35"; }
.ficon-ProFootball:before { content: "\EB27"; }
.ficon-ProgressLoopInner:before { content: "\ECDE"; }
.ficon-ProgressLoopOuter:before { content: "\ECDF"; }
.ficon-ProgressRing5:before { content: "\EDF6"; }
.ficon-ProgressRingDots:before { content: "\F16A"; }
.ficon-ProHockey:before { content: "\EB28"; }
.ficon-ProjectCollection:before { content: "\F363"; }
.ficon-ProjectDocument:before { content: "\F759"; }
.ficon-ProjectLogo16:before { content: "\F480"; }
.ficon-ProjectLogo32:before { content: "\F47E"; }
.ficon-ProjectLogoInverse:before { content: "\EDD4"; }
.ficon-ProjectManagement:before { content: "\E9DE"; }
.ficon-PromotedDatabase:before { content: "\F77D"; }
.ficon-Pronouns:before { content: "\E556"; }
.ficon-ProtectedDocument:before { content: "\E8A6"; }
.ficon-ProtectionCenterLogo32:before { content: "\F494"; }
.ficon-ProtectRestrict:before { content: "\F22A"; }
.ficon-ProvisioningPackage:before { content: "\E835"; }
.ficon-PublicCalendar:before { content: "\EF6D"; }
.ficon-PublicContactCard:before { content: "\EF6E"; }
.ficon-PublicContactCardMirrored:before { content: "\F230"; }
.ficon-PublicEmail:before { content: "\EF6F"; }
.ficon-PublicFolder:before { content: "\EF70"; }
.ficon-PublishContent:before { content: "\F0D4"; }
.ficon-PublishCourse:before { content: "\F699"; }
.ficon-PublisherLogo:before { content: "\F1ED"; }
.ficon-PublisherLogo16:before { content: "\F3A0"; }
.ficon-PublisherLogoInverse16:before { content: "\F39F"; }
.ficon-Puzzle:before { content: "\EA86"; }
.ficon-PY:before { content: "\F2F9"; }
.ficon-PythonLanguage:before { content: "\F2F8"; }
.ficon-QandA:before { content: "\F8A2"; }
.ficon-QandAMirror:before { content: "\F8A3"; }
.ficon-QRCode:before { content: "\ED14"; }
.ficon-QuadColumn:before { content: "\F66F"; }
.ficon-Quantity:before { content: "\E9F8"; }
.ficon-QuarterCircle:before { content: "\F502"; }
.ficon-QueryList:before { content: "\F2B8"; }
.ficon-Questionnaire:before { content: "\EE19"; }
.ficon-QuestionnaireMirrored:before { content: "\EE4B"; }
.ficon-QueueAdvanced:before { content: "\E62E"; }
.ficon-QuickNote:before { content: "\E70B"; }
.ficon-QuickNoteSolid:before { content: "\F338"; }
.ficon-Quotes:before { content: "\F067"; }
.ficon-R:before { content: "\F4EB"; }
.ficon-RadioBtnOff:before { content: "\ECCA"; }
.ficon-RadioBtnOn:before { content: "\ECCB"; }
.ficon-RadioBullet:before { content: "\E915"; }
.ficon-Rain:before { content: "\E9C4"; }
.ficon-RainShowersDay:before { content: "\E9C3"; }
.ficon-RainShowersNight:before { content: "\EA0F"; }
.ficon-RainSnow:before { content: "\E9C7"; }
.ficon-Rate:before { content: "\EB07"; }
.ficon-RawSource:before { content: "\F299"; }
.ficon-Read:before { content: "\E8C3"; }
.ficon-ReadingMode:before { content: "\E736"; }
.ficon-ReadingModeSolid:before { content: "\F33D"; }
.ficon-ReadOutLoud:before { content: "\F112"; }
.ficon-RealEstate:before { content: "\E758"; }
.ficon-ReceiptCheck:before { content: "\EF5B"; }
.ficon-ReceiptForward:before { content: "\EF59"; }
.ficon-ReceiptProcessing:before { content: "\E496"; }
.ficon-ReceiptReply:before { content: "\EF5A"; }
.ficon-ReceiptTentative:before { content: "\F41A"; }
.ficon-ReceiptTentativeMirrored:before { content: "\F41B"; }
.ficon-ReceiptUndelivered:before { content: "\F419"; }
.ficon-Recent:before { content: "\E823"; }
.ficon-Record2:before { content: "\EA3F"; }
.ficon-RecordRouting:before { content: "\E62D"; }
.ficon-RecruitmentManagement:before { content: "\EE12"; }
.ficon-RectangleShape:before { content: "\F1A9"; }
.ficon-RectangleShapeSolid:before { content: "\F640"; }
.ficon-RectangularClipping:before { content: "\F407"; }
.ficon-RecurringEvent:before { content: "\EF5D"; }
.ficon-RecurringTask:before { content: "\EDB2"; }
.ficon-RecycleBin:before { content: "\EF87"; }
.ficon-Redeploy:before { content: "\F29E"; }
.ficon-RedEye:before { content: "\E7B3"; }
.ficon-RedEye12:before { content: "\E3AD"; }
.ficon-Redo:before { content: "\E7A6"; }
.ficon-Refresh:before { content: "\E72C"; }
.ficon-RegistryEditor:before { content: "\F1FF"; }
.ficon-Relationship:before { content: "\F003"; }
.ficon-ReleaseDefinition:before { content: "\F6EA"; }
.ficon-ReleaseGate:before { content: "\F7BE"; }
.ficon-ReleaseGateCheck:before { content: "\F7BF"; }
.ficon-ReleaseGateError:before { content: "\F7C0"; }
.ficon-ReminderGroup:before { content: "\EBF8"; }
.ficon-ReminderPerson:before { content: "\EBF7"; }
.ficon-ReminderTime:before { content: "\EBF9"; }
.ficon-Remote:before { content: "\E8AF"; }
.ficon-RemoteApplication:before { content: "\F621"; }
.ficon-Remove:before { content: "\E738"; }
.ficon-RemoveContent:before { content: "\ECC7"; }
.ficon-RemoveEvent:before { content: "\ED8A"; }
.ficon-RemoveFilter:before { content: "\EB08"; }
.ficon-RemoveFromShoppingList:before { content: "\F7D5"; }
.ficon-RemoveFromTrash:before { content: "\F82B"; }
.ficon-RemoveLink:before { content: "\ED90"; }
.ficon-RemoveLinkChain:before { content: "\F79A"; }
.ficon-RemoveLinkX:before { content: "\F79B"; }
.ficon-RemoveOccurrence:before { content: "\ED9B"; }
.ficon-Rename:before { content: "\E8AC"; }
.ficon-RenewalCurrent:before { content: "\F545"; }
.ficon-RenewalFuture:before { content: "\F546"; }
.ficon-ReopenPages:before { content: "\ED50"; }
.ficon-Repair:before { content: "\E90F"; }
.ficon-RepeatAll:before { content: "\E8EE"; }
.ficon-RepeatHeaderRows:before { content: "\E3EB"; }
.ficon-RepeatOne:before { content: "\E8ED"; }
.ficon-Reply:before { content: "\E97A"; }
.ficon-ReplyAll:before { content: "\EE0A"; }
.ficon-ReplyAllAlt:before { content: "\EF5F"; }
.ficon-ReplyAllMirrored:before { content: "\EE36"; }
.ficon-ReplyAlt:before { content: "\EF5E"; }
.ficon-ReplyMirrored:before { content: "\EE35"; }
.ficon-Repo:before { content: "\F2CB"; }
.ficon-ReportAdd:before { content: "\F52C"; }
.ficon-ReportAlert:before { content: "\F721"; }
.ficon-ReportAlertMirrored:before { content: "\F722"; }
.ficon-ReportDocument:before { content: "\E9F9"; }
.ficon-ReportHacked:before { content: "\E730"; }
.ficon-ReportLibrary:before { content: "\EEBB"; }
.ficon-ReportLibraryMirrored:before { content: "\EEBC"; }
.ficon-ReportLock:before { content: "\F875"; }
.ficon-ReportTrophy:before { content: "\E614"; }
.ficon-ReportWarning:before { content: "\F569"; }
.ficon-RepoSolid:before { content: "\F2CC"; }
.ficon-Rerun:before { content: "\F8A1"; }
.ficon-ReservationOrders:before { content: "\F845"; }
.ficon-Reset:before { content: "\E423"; }
.ficon-ResetDevice:before { content: "\ED10"; }
.ficon-ResponsesMenu:before { content: "\F768"; }
.ficon-ReturnKey:before { content: "\E751"; }
.ficon-ReturnToSession:before { content: "\ED24"; }
.ficon-RevenueManagement:before { content: "\F85E"; }
.ficon-ReviewRequestMirroredSolid:before { content: "\F357"; }
.ficon-ReviewRequestSolid:before { content: "\F356"; }
.ficon-ReviewResponseSolid:before { content: "\F358"; }
.ficon-ReviewSolid:before { content: "\F355"; }
.ficon-RevToggleKey:before { content: "\E845"; }
.ficon-RewardsLogo:before { content: "\ED4E"; }
.ficon-RewardsLogoArt64:before { content: "\EEF2"; }
.ficon-RewardsLogoSolid:before { content: "\ED4F"; }
.ficon-Rewind:before { content: "\EB9E"; }
.ficon-RewindEightX:before { content: "\E449"; }
.ficon-RewindFourX:before { content: "\E448"; }
.ficon-RewindOneFiveX:before { content: "\E446"; }
.ficon-RewindOneX:before { content: "\E445"; }
.ficon-RewindPointFiveX:before { content: "\E444"; }
.ficon-RewindTwoX:before { content: "\E447"; }
.ficon-Ribbon:before { content: "\E9D1"; }
.ficon-Ribbon2:before { content: "\F19B"; }
.ficon-RibbonSolid:before { content: "\F345"; }
.ficon-RightDoubleQuote:before { content: "\E9B1"; }
.ficon-RightTriangle:before { content: "\F500"; }
.ficon-Ringer:before { content: "\EA8F"; }
.ficon-RingerActive:before { content: "\E498"; }
.ficon-RingerOff:before { content: "\F2C5"; }
.ficon-RingerRemove:before { content: "\F279"; }
.ficon-RingerSolid:before { content: "\EF3A"; }
.ficon-Robot:before { content: "\E99A"; }
.ficon-Rocket:before { content: "\F3B3"; }
.ficon-Room:before { content: "\ED9F"; }
.ficon-Rotate:before { content: "\E7AD"; }
.ficon-Rotate90Clockwise:before { content: "\F80D"; }
.ficon-Rotate90CounterClockwise:before { content: "\F80E"; }
.ficon-RowsChild:before { content: "\F29C"; }
.ficon-RowsGroup:before { content: "\F29B"; }
.ficon-Rugby:before { content: "\EB2D"; }
.ficon-Running:before { content: "\EADA"; }
.ficon-Sad:before { content: "\E757"; }
.ficon-SadSolid:before { content: "\F33E"; }
.ficon-SamsungGallery:before { content: "\E4E2"; }
.ficon-Save:before { content: "\E74E"; }
.ficon-SaveAll:before { content: "\F203"; }
.ficon-SaveAndClose:before { content: "\F038"; }
.ficon-SaveAs:before { content: "\E792"; }
.ficon-SavedOffline:before { content: "\E546"; }
.ficon-SaveTemplate:before { content: "\F6EC"; }
.ficon-SaveToMobile:before { content: "\F7E0"; }
.ficon-Savings:before { content: "\EB0B"; }
.ficon-ScaleUp:before { content: "\ED09"; }
.ficon-ScaleVolume:before { content: "\F18C"; }
.ficon-ScatterChart:before { content: "\EFEB"; }
.ficon-ScheduleEventAction:before { content: "\F1EF"; }
.ficon-SchoolDataSyncLogo:before { content: "\E34C"; }
.ficon-ScopeTemplate:before { content: "\F2B0"; }
.ficon-Screen:before { content: "\EF39"; }
.ficon-ScreenCast:before { content: "\F7E2"; }
.ficon-ScreenPreviewOn:before { content: "\F11E"; }
.ficon-ScreenTime:before { content: "\F182"; }
.ficon-Script:before { content: "\F03A"; }
.ficon-ScrollUpDown:before { content: "\EC8F"; }
.ficon-SDCard:before { content: "\E7F1"; }
.ficon-Search:before { content: "\E721"; }
.ficon-SearchAndApps:before { content: "\E773"; }
.ficon-SearchArt64:before { content: "\EEF5"; }
.ficon-SearchBookmark:before { content: "\F5B8"; }
.ficon-SearchCalendar:before { content: "\F4AF"; }
.ficon-SearchData:before { content: "\F3F1"; }
.ficon-SearchIssue:before { content: "\F09A"; }
.ficon-SearchIssueMirrored:before { content: "\F09B"; }
.ficon-SearchNearby:before { content: "\E820"; }
.ficon-SecondaryNav:before { content: "\F814"; }
.ficon-Section:before { content: "\EC0C"; }
.ficon-Sections:before { content: "\EF76"; }
.ficon-SecurityCamera:before { content: "\EB35"; }
.ficon-SecurityGroup:before { content: "\ED85"; }
.ficon-SecurityTest:before { content: "\E48F"; }
.ficon-SeeDo:before { content: "\E808"; }
.ficon-SelectAll:before { content: "\E8B3"; }
.ficon-Sell:before { content: "\EB0C"; }
.ficon-SemiboldWeight:before { content: "\F4F0"; }
.ficon-Send:before { content: "\E724"; }
.ficon-SendMirrored:before { content: "\EA63"; }
.ficon-SentimentAnalysis:before { content: "\E393"; }
.ficon-Separator:before { content: "\F35E"; }
.ficon-Server:before { content: "\F201"; }
.ficon-ServerEnviroment:before { content: "\F29F"; }
.ficon-ServerProcesses:before { content: "\F1FE"; }
.ficon-ServiceActivity:before { content: "\EFF1"; }
.ficon-ServiceOff:before { content: "\E3FD"; }
.ficon-SetAction:before { content: "\F071"; }
.ficon-Settings:before { content: "\E713"; }
.ficon-SettingsAdd:before { content: "\E35A"; }
.ficon-SettingsSecure:before { content: "\E4D2"; }
.ficon-SettingsSync:before { content: "\E359"; }
.ficon-ShakeDevice:before { content: "\F80A"; }
.ficon-Shapes:before { content: "\EC7C"; }
.ficon-ShapeSolid:before { content: "\E422"; }
.ficon-Share:before { content: "\E72D"; }
.ficon-SharedDatabase:before { content: "\E3D9"; }
.ficon-SharedNotes:before { content: "\F481"; }
.ficon-ShareiOS:before { content: "\EF79"; }
.ficon-Sharepoint2013LogoInverse:before { content: "\E480"; }
.ficon-SharepointAppIcon16:before { content: "\E365"; }
.ficon-SharepointLogo:before { content: "\F27E"; }
.ficon-SharepointLogoInverse:before { content: "\ED18"; }
.ficon-Shield:before { content: "\EA18"; }
.ficon-ShieldAlert:before { content: "\F7D7"; }
.ficon-ShieldSolid:before { content: "\F340"; }
.ficon-Shirt:before { content: "\ED00"; }
.ficon-Shop:before { content: "\E719"; }
.ficon-ShoppingCart:before { content: "\E7BF"; }
.ficon-ShoppingCartSolid:before { content: "\F342"; }
.ficon-ShopServer:before { content: "\F2B6"; }
.ficon-Showers:before { content: "\E46C"; }
.ficon-ShowGrid:before { content: "\F7DE"; }
.ficon-ShowResults:before { content: "\E8BC"; }
.ficon-ShowResultsMirrored:before { content: "\EA65"; }
.ficon-ShowTimeAs:before { content: "\F787"; }
.ficon-ShowVisualFilter:before { content: "\F4DE"; }
.ficon-SidePanel:before { content: "\EF52"; }
.ficon-SidePanelMirrored:before { content: "\F221"; }
.ficon-Signin:before { content: "\F286"; }
.ficon-SignOut:before { content: "\F3B1"; }
.ficon-SimplifiedView:before { content: "\E5C1"; }
.ficon-SingleBookmark:before { content: "\EDFF"; }
.ficon-SingleBookmarkSolid:before { content: "\EE00"; }
.ficon-SingleColumn:before { content: "\F1D3"; }
.ficon-SingleColumnEdit:before { content: "\F321"; }
.ficon-SIPMove:before { content: "\E759"; }
.ficon-SiteScan:before { content: "\EBEC"; }
.ficon-SizeLegacy:before { content: "\E2B2"; }
.ficon-SkipBack10:before { content: "\ED3C"; }
.ficon-SkipForward30:before { content: "\ED3D"; }
.ficon-SkiResorts:before { content: "\EB45"; }
.ficon-SkypeArrow:before { content: "\F748"; }
.ficon-SkypeCheck:before { content: "\EF80"; }
.ficon-SkypeCircleArrow:before { content: "\F747"; }
.ficon-SkypeCircleCheck:before { content: "\EF7D"; }
.ficon-SkypeCircleClock:before { content: "\EF7E"; }
.ficon-SkypeCircleMinus:before { content: "\EF7F"; }
.ficon-SkypeCircleSlash:before { content: "\F825"; }
.ficon-SkypeClock:before { content: "\EF81"; }
.ficon-SkypeForBusinessLogo:before { content: "\F0FC"; }
.ficon-SkypeForBusinessLogo16:before { content: "\F40F"; }
.ficon-SkypeForBusinessLogoFill:before { content: "\F27D"; }
.ficon-SkypeForBusinessLogoFill16:before { content: "\F410"; }
.ficon-SkypeLogo:before { content: "\EB6F"; }
.ficon-SkypeLogo16:before { content: "\F40E"; }
.ficon-SkypeMessage:before { content: "\EF83"; }
.ficon-SkypeMinus:before { content: "\EF82"; }
.ficon-SkypeSlash:before { content: "\F826"; }
.ficon-Sleet:before { content: "\E474"; }
.ficon-Slider:before { content: "\F527"; }
.ficon-SliderHandleSize:before { content: "\F528"; }
.ficon-SliderThumb:before { content: "\EC13"; }
.ficon-Slideshow:before { content: "\E786"; }
.ficon-SmartGlassRemote:before { content: "\F80B"; }
.ficon-SnapToGrid:before { content: "\F7E4"; }
.ficon-Snooze:before { content: "\F4BD"; }
.ficon-Snow:before { content: "\E9C8"; }
.ficon-Snowflake:before { content: "\EB46"; }
.ficon-SnowShowerDay:before { content: "\E9FD"; }
.ficon-SnowShowerNight:before { content: "\EA11"; }
.ficon-Soccer:before { content: "\EB21"; }
.ficon-SocialListeningLogo:before { content: "\ED7C"; }
.ficon-Sort:before { content: "\E8CB"; }
.ficon-SortDown:before { content: "\EE69"; }
.ficon-SortLines:before { content: "\E9D0"; }
.ficon-SortLinesAscending:before { content: "\E43A"; }
.ficon-SortUp:before { content: "\EE68"; }
.ficon-Source:before { content: "\EB1B"; }
.ficon-Spacer:before { content: "\F40D"; }
.ficon-Speakers:before { content: "\E7F5"; }
.ficon-SpecialEvent:before { content: "\F536"; }
.ficon-Speech:before { content: "\EFA9"; }
.ficon-SpeechOff:before { content: "\F8BA"; }
.ficon-SpeedHigh:before { content: "\EC4A"; }
.ficon-Spelling:before { content: "\F87B"; }
.ficon-Split:before { content: "\EDBC"; }
.ficon-SplitObject:before { content: "\F547"; }
.ficon-Sprint:before { content: "\F3B0"; }
.ficon-SQLAnalyticsPool:before { content: "\E434"; }
.ficon-SQLServerLogo:before { content: "\E61A"; }
.ficon-Squalls:before { content: "\E9CC"; }
.ficon-SquareShape:before { content: "\F1A6"; }
.ficon-SquareShapeSolid:before { content: "\F63D"; }
.ficon-Stack:before { content: "\F26F"; }
.ficon-StackColumnChart:before { content: "\E9FC"; }
.ficon-StackedBarChart:before { content: "\F24D"; }
.ficon-StackedBarChartFull:before { content: "\F668"; }
.ficon-StackedColumnChart2:before { content: "\F666"; }
.ficon-StackedColumnChart2Fill:before { content: "\F831"; }
.ficon-StackedLineChart:before { content: "\F24E"; }
.ficon-StackIndicator:before { content: "\E7FF"; }
.ficon-StaffNotebookLogo16:before { content: "\F48E"; }
.ficon-StaffNotebookLogo32:before { content: "\F48C"; }
.ficon-StaffNotebookLogoFill16:before { content: "\F48F"; }
.ficon-StaffNotebookLogoFill32:before { content: "\F48D"; }
.ficon-StaffNotebookLogoInverted16:before { content: "\F491"; }
.ficon-StaffNotebookLogoInverted32:before { content: "\F490"; }
.ficon-Starburst:before { content: "\EF78"; }
.ficon-StarburstSolid:before { content: "\F33C"; }
.ficon-StatusCircleBlock:before { content: "\F140"; }
.ficon-StatusCircleBlock2:before { content: "\F141"; }
.ficon-StatusCircleCheckmark:before { content: "\F13E"; }
.ficon-StatusCircleErrorX:before { content: "\F13D"; }
.ficon-StatusCircleExclamation:before { content: "\F13C"; }
.ficon-StatusCircleInfo:before { content: "\F13F"; }
.ficon-StatusCircleInner:before { content: "\F137"; }
.ficon-StatusCircleOuter:before { content: "\F136"; }
.ficon-StatusCircleQuestionMark:before { content: "\F142"; }
.ficon-StatusCircleRing:before { content: "\F138"; }
.ficon-StatusCircleSync:before { content: "\F143"; }
.ficon-StatusErrorFull:before { content: "\EB90"; }
.ficon-StatusTriangle:before { content: "\EA82"; }
.ficon-StatusTriangleExclamation:before { content: "\F13B"; }
.ficon-StatusTriangleInner:before { content: "\F13A"; }
.ficon-StatusTriangleOuter:before { content: "\F139"; }
.ficon-Step:before { content: "\F241"; }
.ficon-StepInsert:before { content: "\F242"; }
.ficon-StepShared:before { content: "\F243"; }
.ficon-StepSharedAdd:before { content: "\F244"; }
.ficon-StepSharedInsert:before { content: "\F245"; }
.ficon-StickyNotesOutlineAppIcon:before { content: "\E36A"; }
.ficon-StickyNotesSolidAppIcon:before { content: "\E36B"; }
.ficon-StockDown:before { content: "\EB0F"; }
.ficon-StockUp:before { content: "\EB11"; }
.ficon-Stop:before { content: "\E71A"; }
.ficon-StopSolid:before { content: "\EE95"; }
.ficon-Stopwatch:before { content: "\E916"; }
.ficon-StorageAcount:before { content: "\E435"; }
.ficon-StorageOptical:before { content: "\E958"; }
.ficon-StoreLogo16:before { content: "\EA96"; }
.ficon-StoreLogoMed20:before { content: "\EA04"; }
.ficon-Storyboard:before { content: "\F308"; }
.ficon-StreamDiscover:before { content: "\F7D9"; }
.ficon-Streaming:before { content: "\E93E"; }
.ficon-StreamingDataflow:before { content: "\E668"; }
.ficon-StreamingDataset:before { content: "\E667"; }
.ficon-StreamingOff:before { content: "\F2BB"; }
.ficon-StreamLogo:before { content: "\F329"; }
.ficon-StreamPlaylist:before { content: "\E669"; }
.ficon-Street:before { content: "\E913"; }
.ficon-StreetsideSplitMinimize:before { content: "\E802"; }
.ficon-Strikethrough:before { content: "\EDE0"; }
.ficon-StrikethroughKorean:before { content: "\E5D4"; }
.ficon-Subscribe:before { content: "\EDA1"; }
.ficon-Subscript:before { content: "\EDDF"; }
.ficon-SubstitutionsIn:before { content: "\EB31"; }
.ficon-SubtractShape:before { content: "\F8FC"; }
.ficon-Suitcase:before { content: "\EDD3"; }
.ficon-SummaryChart:before { content: "\E9FE"; }
.ficon-SunAdd:before { content: "\EF69"; }
.ficon-Sunny:before { content: "\E9BD"; }
.ficon-SunQuestionMark:before { content: "\EF6A"; }
.ficon-Superscript:before { content: "\EDDE"; }
.ficon-SurveyQuestionResponse:before { content: "\E4F2"; }
.ficon-SurveyQuestions:before { content: "\F01B"; }
.ficon-SwayLogo16:before { content: "\F484"; }
.ficon-SwayLogo32:before { content: "\F482"; }
.ficon-SwayLogoFill16:before { content: "\F485"; }
.ficon-SwayLogoFill32:before { content: "\F483"; }
.ficon-SwayLogoInverse:before { content: "\ED29"; }
.ficon-Switch:before { content: "\E8AB"; }
.ficon-SwitcherStartEnd:before { content: "\E810"; }
.ficon-SwitchUser:before { content: "\E748"; }
.ficon-Sync:before { content: "\E895"; }
.ficon-SyncError:before { content: "\EA6A"; }
.ficon-SyncFolder:before { content: "\E8F7"; }
.ficon-SyncOccurence:before { content: "\F4A3"; }
.ficon-SyncOccurenceCancel:before { content: "\F7E7"; }
.ficon-SyncStatus:before { content: "\F751"; }
.ficon-SyncStatusSolid:before { content: "\F752"; }
.ficon-SyncToPC:before { content: "\EE6E"; }
.ficon-System:before { content: "\E770"; }
.ficon-Tab:before { content: "\E7E9"; }
.ficon-TabCenter:before { content: "\F100"; }
.ficon-Table:before { content: "\ED86"; }
.ficon-TableBrandedColumn:before { content: "\E3F1"; }
.ficon-TableBrandedRow:before { content: "\E3EE"; }
.ficon-TableColumn:before { content: "\E4BD"; }
.ficon-TableComputed:before { content: "\F8F5"; }
.ficon-TableFirstColumn:before { content: "\E3EF"; }
.ficon-TableGroup:before { content: "\F6D9"; }
.ficon-TableHeaderRow:before { content: "\E3EC"; }
.ficon-TableLastColumn:before { content: "\E3F0"; }
.ficon-TableLink:before { content: "\F77A"; }
.ficon-TablePermission:before { content: "\E619"; }
.ficon-Tablet:before { content: "\E70A"; }
.ficon-TabletMode:before { content: "\EBFC"; }
.ficon-TableTotalRow:before { content: "\E3ED"; }
.ficon-TabletSelected:before { content: "\EC74"; }
.ficon-TabOneColumn:before { content: "\F849"; }
.ficon-TabThreeColumn:before { content: "\F84B"; }
.ficon-TabTwoColumn:before { content: "\F84A"; }
.ficon-Tag:before { content: "\E8EC"; }
.ficon-TagGroup:before { content: "\E3F6"; }
.ficon-TagSolid:before { content: "\F70E"; }
.ficon-TagUnknown:before { content: "\F6DF"; }
.ficon-TagUnknown12:before { content: "\F6E1"; }
.ficon-TagUnknown12Mirror:before { content: "\F6E2"; }
.ficon-TagUnknownMirror:before { content: "\F6E0"; }
.ficon-TaskAdd:before { content: "\E4FD"; }
.ficon-Taskboard:before { content: "\F1C2"; }
.ficon-TaskGroup:before { content: "\F2AE"; }
.ficon-TaskGroupMirrored:before { content: "\F2AF"; }
.ficon-TaskList:before { content: "\E3B6"; }
.ficon-TaskLogo:before { content: "\F493"; }
.ficon-TaskManager:before { content: "\EDB7"; }
.ficon-TaskManagerMirrored:before { content: "\EDB8"; }
.ficon-TaskSolid:before { content: "\F333"; }
.ficon-Taxi:before { content: "\F4A1"; }
.ficon-TeamFavorite:before { content: "\F2AD"; }
.ficon-TeamsLogo:before { content: "\F27B"; }
.ficon-TeamsLogo16:before { content: "\F40A"; }
.ficon-TeamsLogoInverse:before { content: "\F27A"; }
.ficon-Teamwork:before { content: "\EA12"; }
.ficon-Teeth:before { content: "\F4A0"; }
.ficon-Telemarketer:before { content: "\E7B9"; }
.ficon-TemporaryAccessPass:before { content: "\E4AD"; }
.ficon-TemporaryUser:before { content: "\EE58"; }
.ficon-Tennis:before { content: "\EB33"; }
.ficon-TestAdd:before { content: "\E4DC"; }
.ficon-TestAutoSolid:before { content: "\F3A8"; }
.ficon-TestBeaker:before { content: "\F3A5"; }
.ficon-TestBeakerSolid:before { content: "\F3A6"; }
.ficon-TestCase:before { content: "\F3AF"; }
.ficon-TestExploreSolid:before { content: "\F3A7"; }
.ficon-TestImpactSolid:before { content: "\F3AA"; }
.ficon-TestParameter:before { content: "\F3AD"; }
.ficon-TestPlan:before { content: "\F3AB"; }
.ficon-TestRemove:before { content: "\E4E1"; }
.ficon-TestStep:before { content: "\F3AC"; }
.ficon-TestSuite:before { content: "\F3AE"; }
.ficon-TestUserSolid:before { content: "\F3A9"; }
.ficon-TextAlignBottom:before { content: "\E3E2"; }
.ficon-TextAlignMiddle:before { content: "\E3E1"; }
.ficon-TextAlignTop:before { content: "\E3E0"; }
.ficon-TextBox:before { content: "\EDC2"; }
.ficon-TextCallout:before { content: "\F2A2"; }
.ficon-TextDocument:before { content: "\F029"; }
.ficon-TextDocumentEdit:before { content: "\E43B"; }
.ficon-TextDocumentSettings:before { content: "\E4AA"; }
.ficon-TextDocumentShared:before { content: "\F02B"; }
.ficon-TextField:before { content: "\EDC3"; }
.ficon-TextOverflow:before { content: "\F51F"; }
.ficon-TextParagraphOption:before { content: "\E3E3"; }
.ficon-TextRecognition:before { content: "\E394"; }
.ficon-TextRotate270Degrees:before { content: "\E3E7"; }
.ficon-TextRotate90Degrees:before { content: "\E3E6"; }
.ficon-TextRotateHorizontal:before { content: "\E3E5"; }
.ficon-TextRotation:before { content: "\E3E4"; }
.ficon-TFVCLogo:before { content: "\F44D"; }
.ficon-ThisPC:before { content: "\EC4E"; }
.ficon-ThreeQuarterCircle:before { content: "\F503"; }
.ficon-ThumbnailView:before { content: "\E7B6"; }
.ficon-ThumbnailViewMirrored:before { content: "\EA67"; }
.ficon-Thunderstorms:before { content: "\E9C6"; }
.ficon-Ticket:before { content: "\EB54"; }
.ficon-Tiles:before { content: "\ECA5"; }
.ficon-Tiles2:before { content: "\EF7C"; }
.ficon-TimeEntry:before { content: "\EF95"; }
.ficon-Timeline:before { content: "\ED9C"; }
.ficon-TimelineDelivery:before { content: "\F2AB"; }
.ficon-TimelineMatrixView:before { content: "\F361"; }
.ficon-TimelineProgress:before { content: "\F2AA"; }
.ficon-TimePicker:before { content: "\E367"; }
.ficon-Timer:before { content: "\E91E"; }
.ficon-TimeSheet:before { content: "\EA05"; }
.ficon-Title:before { content: "\F23B"; }
.ficon-TitleMirrored:before { content: "\F23C"; }
.ficon-ToDoLogoBottom:before { content: "\F4B3"; }
.ficon-ToDoLogoInverse:before { content: "\F4BC"; }
.ficon-ToDoLogoOutline:before { content: "\F75B"; }
.ficon-ToDoLogoTop:before { content: "\F4B4"; }
.ficon-ToggleBorder:before { content: "\EC12"; }
.ficon-ToggleFilled:before { content: "\EC11"; }
.ficon-ToggleLeft:before { content: "\F19E"; }
.ficon-ToggleRight:before { content: "\F19F"; }
.ficon-ToggleThumb:before { content: "\EC14"; }
.ficon-Toll:before { content: "\F160"; }
.ficon-Toolbox:before { content: "\ECED"; }
.ficon-Total:before { content: "\E9DF"; }
.ficon-Touch:before { content: "\E815"; }
.ficon-TouchPointer:before { content: "\E7C9"; }
.ficon-Trackers:before { content: "\EADF"; }
.ficon-TrackersMirrored:before { content: "\EE92"; }
.ficon-Train:before { content: "\E7C0"; }
.ficon-TrainSolid:before { content: "\EB4D"; }
.ficon-TransferCall:before { content: "\ED95"; }
.ficon-Transition:before { content: "\F3BC"; }
.ficon-TransitionEffect:before { content: "\F5B4"; }
.ficon-TransitionPop:before { content: "\F5B2"; }
.ficon-TransitionPush:before { content: "\F5B3"; }
.ficon-Translate:before { content: "\E7B2"; }
.ficon-Transportation:before { content: "\EBF1"; }
.ficon-Trending12:before { content: "\F62D"; }
.ficon-TriangleDown12:before { content: "\EED1"; }
.ficon-TriangleLeft12:before { content: "\EED2"; }
.ficon-TriangleRight12:before { content: "\EED3"; }
.ficon-TriangleShape:before { content: "\F1A7"; }
.ficon-TriangleShapeSolid:before { content: "\F63E"; }
.ficon-TriangleSolid:before { content: "\EA08"; }
.ficon-TriangleSolidDown12:before { content: "\EECD"; }
.ficon-TriangleSolidLeft12:before { content: "\EECE"; }
.ficon-TriangleSolidRight12:before { content: "\EECF"; }
.ficon-TriangleSolidUp12:before { content: "\EECC"; }
.ficon-TriangleUp12:before { content: "\EED0"; }
.ficon-TriggerApproval:before { content: "\F3B2"; }
.ficon-TriggerAuto:before { content: "\F24A"; }
.ficon-TriggerPhrase:before { content: "\E5FF"; }
.ficon-TriggerUser:before { content: "\F24B"; }
.ficon-Trim:before { content: "\E78A"; }
.ficon-TrimEnd:before { content: "\F8BC"; }
.ficon-TrimStart:before { content: "\F8BB"; }
.ficon-TripleColumn:before { content: "\F1D5"; }
.ficon-TripleColumnEdit:before { content: "\F323"; }
.ficon-TripleColumnWide:before { content: "\F66E"; }
.ficon-Trophy:before { content: "\ED3F"; }
.ficon-Trophy2:before { content: "\F1AE"; }
.ficon-Trophy2Solid:before { content: "\F337"; }
.ficon-TurnRight:before { content: "\E7DB"; }
.ficon-TVMonitor:before { content: "\E7F4"; }
.ficon-TVMonitorSelected:before { content: "\EC77"; }
.ficon-TypeScriptLanguage:before { content: "\F2F7"; }
.ficon-Umbrella:before { content: "\EC04"; }
.ficon-UnavailableOffline:before { content: "\E545"; }
.ficon-Underline:before { content: "\E8DC"; }
.ficon-UnderlineA:before { content: "\E5CC"; }
.ficon-UnderlineKorean:before { content: "\E5BB"; }
.ficon-UnderlineP:before { content: "\E5CD"; }
.ficon-UnderlineRussian:before { content: "\E5B8"; }
.ficon-UnderlineS:before { content: "\E5B4"; }
.ficon-UnderlineSerbian:before { content: "\E5CB"; }
.ficon-Undo:before { content: "\E7A7"; }
.ficon-Uneditable:before { content: "\ED1D"; }
.ficon-Uneditable2:before { content: "\F876"; }
.ficon-Uneditable2Mirrored:before { content: "\F877"; }
.ficon-UneditableMirrored:before { content: "\F4B9"; }
.ficon-UneditableSolid12:before { content: "\F4B7"; }
.ficon-UneditableSolidMirrored12:before { content: "\F4B8"; }
.ficon-Unfavorite:before { content: "\E8D9"; }
.ficon-UngroupObject:before { content: "\F4F2"; }
.ficon-UniteShape:before { content: "\F8FB"; }
.ficon-Unknown:before { content: "\E9CE"; }
.ficon-UnknownCall:before { content: "\ED97"; }
.ficon-UnknownMirrored:before { content: "\F22E"; }
.ficon-UnknownMirroredSolid:before { content: "\F2E2"; }
.ficon-UnknownSolid:before { content: "\F2E1"; }
.ficon-Unlock:before { content: "\E785"; }
.ficon-UnlockSolid:before { content: "\F304"; }
.ficon-Unpin:before { content: "\E77A"; }
.ficon-UnpublishContent:before { content: "\E31F"; }
.ficon-UnSetColor:before { content: "\F3F9"; }
.ficon-UnstackSelected:before { content: "\E7FE"; }
.ficon-Unsubscribe:before { content: "\EDA0"; }
.ficon-UnsyncFolder:before { content: "\E8F6"; }
.ficon-UnsyncOccurence:before { content: "\F4A4"; }
.ficon-Untag:before { content: "\F60B"; }
.ficon-Up:before { content: "\E74A"; }
.ficon-UpdateRestore:before { content: "\E777"; }
.ficon-UpgradeAnalysis:before { content: "\EA0B"; }
.ficon-Upload:before { content: "\E898"; }
.ficon-UpperCase:before { content: "\E5ED"; }
.ficon-URLBlock:before { content: "\E3FE"; }
.ficon-USB:before { content: "\E88E"; }
.ficon-UserClapper:before { content: "\E666"; }
.ficon-UserEvent:before { content: "\F69C"; }
.ficon-UserFollowed:before { content: "\F25C"; }
.ficon-UserGauge:before { content: "\F6ED"; }
.ficon-UserOptional:before { content: "\F767"; }
.ficon-UserPause:before { content: "\F2BA"; }
.ficon-UserRemove:before { content: "\F69B"; }
.ficon-UserSync:before { content: "\F2B9"; }
.ficon-UserWarning:before { content: "\E368"; }
.ficon-UserWindow:before { content: "\E4ED"; }
.ficon-Vacation:before { content: "\F49F"; }
.ficon-Vaccination:before { content: "\EAE0"; }
.ficon-VaccinationRecent:before { content: "\E550"; }
.ficon-Variable:before { content: "\F305"; }
.ficon-Variable2:before { content: "\F86D"; }
.ficon-Variable3:before { content: "\E4D1"; }
.ficon-VariableGroup:before { content: "\F31B"; }
.ficon-VB:before { content: "\F2F2"; }
.ficon-VennDiagram:before { content: "\F273"; }
.ficon-VerifiedBrand:before { content: "\F7BD"; }
.ficon-VerifiedBrandSolid:before { content: "\F6AD"; }
.ficon-VersionControlPush:before { content: "\F664"; }
.ficon-VerticalDistributeCenter:before { content: "\F4FA"; }
.ficon-Video:before { content: "\E714"; }
.ficon-Video360Generic:before { content: "\F609"; }
.ficon-VideoAdd:before { content: "\E430"; }
.ficon-VideoLightOff:before { content: "\EA74"; }
.ficon-VideoOff:before { content: "\F4B0"; }
.ficon-VideoOff2:before { content: "\E43C"; }
.ficon-VideoSearch:before { content: "\F4EA"; }
.ficon-VideoSolid:before { content: "\EA0C"; }
.ficon-View:before { content: "\E890"; }
.ficon-ViewAll:before { content: "\E8A9"; }
.ficon-ViewAll2:before { content: "\EF56"; }
.ficon-ViewDashboard:before { content: "\F246"; }
.ficon-ViewInAR:before { content: "\E41F"; }
.ficon-ViewList:before { content: "\F247"; }
.ficon-ViewListGroup:before { content: "\F248"; }
.ficon-ViewListTree:before { content: "\F249"; }
.ficon-ViewOriginal:before { content: "\E7B4"; }
.ficon-VirtualNetwork:before { content: "\F815"; }
.ficon-VisioDiagram:before { content: "\F2A0"; }
.ficon-VisioDiagramSync:before { content: "\F762"; }
.ficon-VisioDocument:before { content: "\F2A9"; }
.ficon-VisioLogo:before { content: "\F2A7"; }
.ficon-VisioLogo16:before { content: "\F3A3"; }
.ficon-VisioLogoInverse:before { content: "\ED7D"; }
.ficon-VisioLogoInverse16:before { content: "\F3A2"; }
.ficon-VisualBasicLanguage:before { content: "\F2F1"; }
.ficon-VisuallyImpaired:before { content: "\F866"; }
.ficon-VisualsFolder:before { content: "\F520"; }
.ficon-VisualsStore:before { content: "\F521"; }
.ficon-VisualStudioForWindows:before { content: "\F5D0"; }
.ficon-VisualStudioForWindowsAlt:before { content: "\EC22"; }
.ficon-VoicemailForward:before { content: "\ED87"; }
.ficon-VoicemailIRM:before { content: "\F421"; }
.ficon-VoicemailReply:before { content: "\ED88"; }
.ficon-Volume0:before { content: "\E992"; }
.ficon-Volume1:before { content: "\E993"; }
.ficon-Volume2:before { content: "\E994"; }
.ficon-Volume3:before { content: "\E995"; }
.ficon-VolumeDisabled:before { content: "\EA85"; }
.ficon-VSTSAltLogo1:before { content: "\F382"; }
.ficon-VSTSAltLogo2:before { content: "\F383"; }
.ficon-VSTSLogo:before { content: "\F381"; }
.ficon-Waffle:before { content: "\ED89"; }
.ficon-WaffleOffice365:before { content: "\F4E0"; }
.ficon-WaitlistConfirm:before { content: "\F550"; }
.ficon-WaitlistConfirmMirrored:before { content: "\F551"; }
.ficon-Warning:before { content: "\E7BA"; }
.ficon-Warning12:before { content: "\F62F"; }
.ficon-WarningSolid:before { content: "\F736"; }
.ficon-WavingHand:before { content: "\F807"; }
.ficon-WebAppBuilderFragment:before { content: "\E314"; }
.ficon-WebAppBuilderFragmentCreate:before { content: "\E31B"; }
.ficon-WebAppBuilderFragmentLock:before { content: "\E4DD"; }
.ficon-WebAppBuilderFragmentTest:before { content: "\E4DE"; }
.ficon-WebAppBuilderModule:before { content: "\E313"; }
.ficon-WebAppBuilderModuleLock:before { content: "\E4DF"; }
.ficon-WebAppBuilderModuleTest:before { content: "\E4E0"; }
.ficon-WebAppBuilderSlot:before { content: "\E315"; }
.ficon-Webcam2:before { content: "\E960"; }
.ficon-Webcam2Off:before { content: "\E36D"; }
.ficon-WebComponents:before { content: "\EC8B"; }
.ficon-WebEnvironment:before { content: "\E3DB"; }
.ficon-WebPublish:before { content: "\F52F"; }
.ficon-WebSearch:before { content: "\F6FA"; }
.ficon-Website:before { content: "\EB41"; }
.ficon-WebTemplate:before { content: "\F6B2"; }
.ficon-Weights:before { content: "\EADB"; }
.ficon-Wheelchair:before { content: "\F31F"; }
.ficon-Whiteboard:before { content: "\F784"; }
.ficon-WhiteBoardApp16:before { content: "\F673"; }
.ficon-WhiteBoardApp32:before { content: "\F674"; }
.ficon-WifiEthernet:before { content: "\EE77"; }
.ficon-WifiWarning4:before { content: "\EB63"; }
.ficon-WindDirection:before { content: "\EBE6"; }
.ficon-WindowEdit:before { content: "\F50E"; }
.ficon-WindowsLogo:before { content: "\E782"; }
.ficon-Wines:before { content: "\EABF"; }
.ficon-WipePhone:before { content: "\ED8D"; }
.ficon-WordDocument:before { content: "\EF71"; }
.ficon-WordLogo:before { content: "\F1E3"; }
.ficon-WordLogo16:before { content: "\F391"; }
.ficon-WordLogoInverse:before { content: "\EC29"; }
.ficon-WordLogoInverse16:before { content: "\F390"; }
.ficon-Work:before { content: "\E821"; }
.ficon-WorkFlow:before { content: "\EA01"; }
.ficon-WorkforceManagement:before { content: "\EE0F"; }
.ficon-WorkItem:before { content: "\F314"; }
.ficon-WorkItemAlert:before { content: "\F78F"; }
.ficon-WorkItemBar:before { content: "\F35C"; }
.ficon-WorkItemBarSolid:before { content: "\F35D"; }
.ficon-WorkItemBug:before { content: "\F315"; }
.ficon-World:before { content: "\E909"; }
.ficon-WorldClock:before { content: "\E918"; }
.ficon-XPowerY:before { content: "\F7CA"; }
.ficon-XRay:before { content: "\E551"; }
.ficon-YammerLogo:before { content: "\ED19"; }
.ficon-ZeroDayCalendar:before { content: "\E547"; }
.ficon-ZeroDayPatch:before { content: "\E665"; }
.ficon-ZipFolder:before { content: "\F012"; }
.ficon-Zoom:before { content: "\E71E"; }
.ficon-ZoomIn:before { content: "\E8A3"; }
.ficon-ZoomOut:before { content: "\E71F"; }
.ficon-ZoomToFit:before { content: "\F649"; }
