// @import "assets/themes/material/material-light/compact/indigo/variables";
@use 'sass:color';
$primaryColor: hsl(192, 100%, 43%);
$lightPrimaryColor: color.change(color.adjust($primaryColor, $hue: 3deg), $lightness: 67%); // Used for solid elements, such as buttons and dropdowns
$lighterPrimaryColor: color.change(color.adjust($primaryColor, $hue: 3deg), $lightness: 82%); // Used for solid elements, such as buttons and dropdowns

// Used for sections that need to use a very light shade of the primary color as their background color, such as the background color of the .actions-bar
$lightBackgroundPrimaryColor: color.change($primaryColor, $lightness: 97%);
// Used for borders of sections that use the $lightBackgroundPrimaryColor as their background color, such as the border color of the .actions-bar
$lightBackgroundPrimaryBorderColor: color.change($primaryColor, $saturation: 40%, $lightness: 80%);
$text-color: #333333;
$red-text-color: #d60000;
$modifications-notice-color: #ae00f9;

$fontFamilyFallback: "Helvetica Neue", sans-serif;
$fontFamilyPlainText: "Roboto", $fontFamilyFallback;
$fontFamilyNonPlainText: "titillium-web", $fontFamilyFallback;
$fontFamilyCode: "IBM Plex Mono", monospace;

$buttonHeight: 34px;

$navbarWidth: 270px;
$logoContainerHeight: 90px;

$scrollbarWidth: 17px;

//008cff
//007be0
//#00a5e0
//#0073ff
//#0091e6

//#00c3ff cyan
//#38d1ff light cyan
//#47d4ff lighter cyan
//#57d8ff lighter cyan
