/* Customizations to the designer theme should be defined here */

.p-button {
	// &, &.p-button-success, &.p-button-danger, &.p-button-outlined, &.p-button-danger.p-button-outlined {
	// 	border-width: 0px 0px 2px 0px;
	// 	border-style: solid;
	// }

	// &, &.p-button-success, &.p-button-danger, &.p-button-outlined, &.p-button-danger.p-button-outlined {
	// 	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1);

	// 	&:enabled:hover {
	// 		border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
	// 	}
	// }
}

p-table.marginless {
	.p-datatable-thead > tr > th:first-of-type, .p-datatable-tbody > tr > td:first-of-type {
		padding-left: 0;
	}
	.p-datatable-thead > tr > th:last-of-type, .p-datatable-tbody > tr > td:last-of-type {
		padding-right: 0;
	}
}

.p-datatable .p-datatable-thead > tr > th {
	color: #888888;
}
