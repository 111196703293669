@import "/src/variables";
@import "/src/fabric-icons";
// @import "primeng/resources/themes/md-light-indigo/theme.css";
@import "primeng/resources/primeng.css";
@import "assets/themes/material/_compact.scss";
@import "assets/themes/material/material-light/compact/indigo/theme.scss";
@import "assets/themes/material/material-light/compact/indigo/extensions.scss";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
// ol, ul {
// 	list-style: none;
// }
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* End of reset */

html, body { height: 100%; }
body {
	color: #333333;
	line-height: 24px;
	margin: 0;
}

body, .section-title, input, select, .mat-table, .mat-cell, .mat-footer-cell {
	font-family: $fontFamilyPlainText;
}
.sidebar, .section-title, .button {
	font-family: $fontFamilyNonPlainText;
}
body, .section-title, input, select, .mat-table, .mat-cell, .mat-footer-cell {
  font-size: 14px;
}

:root {
	--ngx-json-null: #999;
	--ngx-json-null-bg: transparent;
	--ngx-json-font-family: $fontFamilyCode;
	// --ngx-json-key: #4439a5;
	--ngx-json-string: #007e9e;
	--ngx-json-boolean: #d100d1;
	--ngx-json-number: #008a00;

}

.ngx-json-viewer .segment {
	.segment-main .toggler {
		&:after {
			content: "\e970" !important;
			font-family: "FabricMDL2Icons" !important;
		}
		margin-top: 10px !important;
		font-size: .6em !important;
	}
}
ngx-json-viewer.no-left-margin {
	margin-left: -14px !important; // Compensate for the margin left on the ngx-json-viewer component.
}

@font-face {
  font-family: 'FabricMDL2Icons';
  src: url('/assets/fonts/fabric-icons-a9789667.woff') format('woff');
}

.material-symbols-sharp {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.code {
	font-family: 'IBM Plex Mono', monospace;
	font-size: 13px;
}

.bold {
	font-weight: 700;
}

.extra-bold {
	font-weight: 900;
}

a {
	color: darken($primaryColor, 5%);
	transition: all 0.1s;
	&:hover {
		color: lighten($primaryColor, 6%);
	}
	&:active {
		transition: none;
		color: darken($primaryColor, 10%);
	}
}

.ficon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'FabricMDL2Icons';
  font-style: normal;
  font-weight: normal;
  speak: none;
	&.flip-vertically {
		transform: scaleY(-1);
	}
	&.flip-horizontally {
		transform: scaleX(-1);
	}
	&.rotate-90 {
		transform: rotate(90deg);
	}
}

.p-toast-message-content {
	// Prevents the toast from being cut off
  overflow-wrap: anywhere;
}

.button {
	display: inline-flex;
	text-transform: uppercase;
	white-space: nowrap;
	line-height: 28px;
	height: 34px; // Only here to fix buggy display scaling on windows and possibly other OSes. The line-height + padding + border alone should result in a height of 34px, but for some reason if you have display scaling enabled it adds up to 33.2px (or another value that's not 34px). So we force 34px here.
	background-color: $lighterPrimaryColor;
	padding: 2px 10px 0 10px;
	font-family: "titillium-web";
	font-size: 15px;
	font-weight: 600;
	cursor: pointer;
	border-width: 1px 1px 3px 1px;
	border-style: solid;
	border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.3) rgba(0,0,0,0.1);
	transition: all 0.25s;
	text-decoration: none;
	justify-content: center;
	align-items: center;

	&, &:hover, &:active {
		color: #222222;
	}

	&:hover {
		background-color: lighten($lightPrimaryColor, 5%);
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.4));
	}

	&:active {
		background-color: darken($lightPrimaryColor, 10%);
		filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0));
		border-color: rgba(0,0,0,0.4);
		transition: none;
	}

	&.gray {
		&, &:hover, &:active {
			color: #333333;
		}
		background-color: #efefef;
		border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.2) rgba(0,0,0,0.1);

		&:hover {
			background-color: lighten(#efefef, 2%);
			filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
		}

		&:active {
			background-color: darken(#efefef, 10%);
			filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0));
			border-color: rgba(0,0,0,0.25);
			border-width: 1px 1px 3px 1px;
		}
	}

	&.red {
		&, &:hover, &:active {
			color: #ffffff;
		}
		background-color: darken(#ff0000, 10%);
		border-color: rgba(0,0,0,0.35) rgba(0,0,0,0.35) rgba(0,0,0,0.35) rgba(0,0,0,0.35);

		&:hover {
			background-color: darken(#ff0000, 1%);
			filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.6));
		}

		&:active {
			background-color: darken(#ff0000, 18%);
			filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0));
			// border-color: rgba(0,0,0,0.4);
		}
	}

	.ficon, .label {
		display: inline-block;
		vertical-align: top;
	}
	.ficon {
		margin-right: 10px;
		&.icon-on-right {
			margin-right: 0;
			margin-left: 10px;
		}
		font-size: 13px;
		&.icon-only {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.gray-text {
	color: #666666;
}

a.gray {
	color: #666666;
	&:hover {
		color: lighten(#666666, 10%);
	}
	&:active {
		color: darken(#666666, 10%);
	}
	// &.primary {
	// 	color: darken($lightPrimaryColor, 30%);
	// 	&:hover {
	// 		color: lighten(darken($lightPrimaryColor, 30%), 15%);
	// 	}
	// 	&:active {
	// 		color: darken(darken($lightPrimaryColor, 30%), 15%);
	// 	}
	// }
}

.add-left-page-margin, .add-all-page-margins {
	margin-left: 70px;
}
.add-right-page-margin, .add-all-page-margins {
	margin-right: 70px;
}
.add-top-page-margin, .add-all-page-margins {
	margin-top: 70px;
}
.add-bottom-page-margin, .add-all-page-margins {
	margin-bottom: 70px;
}
.remove-left-page-margin {
	margin-left: -70px; // Apply to elements in order to compensate for the added page margin, so that you can have a borderless look for your elements.
}

.full-width {
	width: 100%;
}

.p-datatable-table {
	.p-datatable-thead {
		border-bottom: 2px solid #d9d9d9;
		th {
			text-transform: uppercase;
			font-size: 16px;
			color: #888888;
			font-weight: 600;
		}
	}
	mat-row {
		border-bottom-color: #d9d9d9;
	}
	.p-datatable-thead, mat-row {
    column-gap: 20px;
	}
	mat-cell {
		line-height: 25px;
	}

	th:first-of-type, mat-cell:first-of-type {
		padding-left: 0;
	}

	&.marginless {
		.p-datatable-thead {
			min-height: 36px;
			th {
				align-items: flex-start;
			}
		}
	}
}

.subsection-title {
	font-weight: 600;
	font-size: 16px;
	color: #666666;
	font-family: $fontFamilyNonPlainText;
}

.subsection-smallcaps-title {
	font-weight: 600;
	font-size: 15px;
	line-height: 34px;
	color: #666666;
	font-family: $fontFamilyNonPlainText;
	text-transform: uppercase;
}

.section-description {
	font-weight: 400;
	line-height: 24px;
	font-size: 15px;
	color: #666666;
	font-family: $fontFamilyPlainText;
}

.section-title {
	display: flex;
	text-transform: uppercase;
	font-weight: 700;
	color: #666666;
	font-size: 20px;
	margin-bottom: 25px;
	line-height: 15px; // This makes it marginless.
	&.light {
		color: #888888;
	}
}

.custom-select-wrapper {
	position: relative;
}
.custom-select {
	color: #ffffff;
	display: inline-flex;
	line-height: 34px;
	min-height: 34px;
	position: relative;
	width: max-content;

	.primary-label {
		background-color: #aaaaaa;
		color: white;
		display: flex;
		gap: 0px 8px;
		// font-family: $fontFamilyNonPlainText;
	}
	.secondary-label {
		background-color: #efefef;
		color: #333333;
		font-weight: bold;
		height: 34px;
		display: flex;
    gap: 0 7px;

		.show-as-older-state {
			font-weight: 400;
			color: #777777;
			display: contents;
		}
		.ficon.transformation-icon {
			color: #777777;
		}
		// font-family: $fontFamilyNonPlainText;
		.ficon {
			font-size: 14px;
			height: 34px;
		}
		&:empty {
			padding: 0;
			&::before {
				display: none;
			}
		}
	}
	.primary-label, .secondary-label {
		padding: 0 11px;
		transition: all 0.15s;
		z-index: 130;
		position: relative;
    align-items: center;
		justify-content: space-between;
		.ficon.transformation-icon {
			margin: 0px 7px 0px 5px;
			font-size: 20px;
			transform: rotate(-90deg) translateX(1px);
		}
	}

	&.enabled {
		.primary-label {
			background-color: #444444;
			color: white;
		}
		&.unselected {
			.primary-label {
				font-weight: 500;
			}
		}
		.secondary-label {
			background-color: $lighterPrimaryColor;
			color: #222222;
		}

		&:hover {
			.primary-label {
				background-color: #1f1f1f;
			}
			.secondary-label {
				background-color: darken($lighterPrimaryColor, 10%);
				&::before {
					content: "";
					position: absolute;
					left: 50%;
					margin-left: -7px;
					bottom: -12px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 13px 7px 0 7px;
					border-color: darken($lighterPrimaryColor, 10%) transparent transparent transparent;
				}
			}
		}

		&:hover {
			&.modified {
				.secondary-label {
					background-color: darken(#efefef, 10%);
					&::after {
						border-bottom-color: darken($lighterPrimaryColor, 10%);
					}
				}
			}
		}

		&.modified {
			.secondary-label {
				background-color: #efefef;
				color: #333333;
			}
			.primary-label, .secondary-label {
				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					width: 100%;
					height: 3px;
					border-bottom: 3px solid $lightPrimaryColor;
				}
				&.no-dirty-indicator {
					&::after {
						display: none;
					}
				}
			}
		}

		.dropdown-wrapper {
			position: absolute;
			bottom: 1px;
			left: 0;
			right: 0;
		}
		.dropdown {
			background-color: #efefef;
			color: #333333;
			display: none;
			position: absolute;
			top: 0;
			left: 0px;
			min-width: 100%;
			width: max-content;
			max-width: 50vw;
			overflow-y: auto;
			max-height: 400px;
			z-index: 125;
			padding: 12px 0 11px;
			border-width: 1px 1px 3px 1px;
			border-style: solid;
			border-color: rgba(0,0,0,0.2);
			font-weight: 500;

			.dropdown-option {
				gap: 0 10px;
				white-space: nowrap;
			}

			.group-title {
				text-transform: uppercase;
				color: #888888;
				margin-left: 11px;
				margin-right: 11px;
				font-weight: 600;
				font-size: 15px;
				line-height: 24px;
				&.only-display-first-within-group {
					~ .group-title {
						display: none;
					}
				}
			}

			.dropdown-option + .group-title, .dropdown-option + .group-title {
				margin-top: 7px;
			}

			> label.dropdown-option, > .dropdown-option {
				padding: 0 11px;
				display: flex;
				position: relative;
				align-items: center;
				font-weight: normal;
				cursor: pointer;
				// font-family: $fontFamilyNonPlainText;
				p-checkbox, p-radioButton {
					margin: 0 7px 0 0;
				}
				.ficon + .text-label {
					margin-left: 7px;
				}
				.text-label {
					line-height: 35px;
				}
				&.initially-selected {
					background-color: #d8d8d8;
				}
				&:hover, &.newly-selected {
					background-color: $lighterPrimaryColor;
					color: #333333;
				}
				&.newly-selected {
					font-weight: 500;
				}
				&.implicitly-selected {
					p-checkbox {
						opacity: 0;
						transition: all 0.15s;
					}
					.implicitly-selected-icon {
						position: absolute;
						left: 0;
						top: 0;
						height: 35px;
						line-height: 35px;
						margin: 1px 0 0 13px;
						.ficon {
							font-size: 20px;
						}
					}
				}
				&:active {
					background-color: darken($lightPrimaryColor, 8%);
				}
			}
			> .dropdown-option.none-option {
				// border-bottom: 1px solid #888888;
				// font-style: italic;
				color: #666;
			}
			.divider-line-and-margin {
				border-top: 1px solid #ccc;
				margin: 6px 8px;
			}

			// > label.dropdown-option:hover, > .dropdown-option:hover, > label.dropdown-option.newly-selected, > .dropdown-option.newly-selected {
			// 	background-color: $lighterPrimaryColor;
			// 	color: #333333;
			// }
			// > label.dropdown-option.newly-selected, > .dropdown-option.newly-selected {
			// 	font-weight: 500;
			// }
			// > label.dropdown-option:active, > .dropdown-option:active {
			// 	background-color: darken($lightPrimaryColor, 8%);
			// }
		}
		&:hover {
			.dropdown {
				display: block;
			}
		}
	}

	&.error {
		.primary-label {
			background-color: #bb0000;
			font-weight: 500;
			font-size: 15px;
		}
		.secondary-label {
			&::after {
				position: absolute;
				content: "";
				width: 100%;
				height: 3px;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: $red-text-color;
			}
		}
	}
	&.spaced-secondary-labels {
		.secondary-label {
			margin-left: 10px;
		}
	}
}

.card {
	background: var(--surface-card);
	padding: 2rem;
	border-radius: 10px;
	margin-bottom: 1rem;
}

p-checkbox ~ label {
	cursor: pointer;
}

.action-link {
	cursor: pointer;
	text-decoration: none; // Prevents the link from being underlined
	position: relative;
	display: inline-flex;
	color: #000000;
	font-weight: 400;
	gap: 0 8px;
	text-transform: uppercase;
	line-height: 34px;
	font-size: 15px;
	&.non-uppercase {
		text-transform: none;
		font-size: 16px;
	}
	font-family: $fontFamilyNonPlainText;

	&:not(.no-underline):after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0px;
		height: 1px;
		// background-color: #999999;
		border-bottom: 1px solid #555555;
	}
	&, &:after {
		transition: all 0.15s;
	}

	.ficon {
		font-size: 12px;
	}

	.modifications-notice {
		color: $modifications-notice-color; // #b200ff;// #9a9a00;
		font-weight: 600;
		padding-right: 2px;
	}

	&:hover {
		color: #999999;

		.modifications-notice {
			color: lighten($modifications-notice-color, 20%);
		}
		&:after {
			// background-color: #cccccc;
			border-bottom-color: #aaaaaa;
		}
	}
	&:active {
		transition: none;
		color: #000000;

		.modifications-notice {
			color: darken($modifications-notice-color, 10%);
		}
		&:after {
			transition: none;
			border-bottom-color: #000000;
		}
	}

	&.danger {
		color: $red-text-color;
		&:after {
			border-bottom-color: $red-text-color;
		}
		&:hover {
			color: lighten($red-text-color, 15%);
			&:after {
				border-bottom-color: lighten($red-text-color, 15%);
			}
		}
		&:active {
			color: darken($red-text-color, 10%);
			&:after {
				border-bottom-color: darken($red-text-color, 10%);
			}
		}
	}
	&.on-dark-background {
		color: #ccc;
		&:after {
			border-bottom-color: #ccc;
		}
		&:hover {
			color: #fff;
			&:after {
				border-bottom-color: #fff;
			}
		}
		&:active {
			color: #999;
			&:after {
				border-bottom-color: #999;
			}
		}
	}
}


.disabler-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	opacity: 0.4;
	z-index: 200;
}

.actions-bar {
	padding: 30px 70px 40px;
	// border-top: 2px solid #ccc;
	// border-top: 2px solid hsl(192 40% 80% / 1); // TODO Make this a relative color (relative to the primary color). And make it a variable.
	// box-shadow: 0 -2px 10px -5px rgba(0,0,0,0.25);
	// background-color: #f7f7f7;
	// background-color: #e5faff;
	// background-color: hsl(192 100% 97% / 1); // TODO Make this a relative color (relative to the primary color). And make it a variable.
	// background-color: lighten(hsl(192 100% 97% / 1), 5%);
	border-top: 2px solid $lightBackgroundPrimaryBorderColor;
	background-color: $lightBackgroundPrimaryColor;
	position: sticky;
	bottom: 0;
	z-index: 10000; // Needed on scopes and snapshots page so that the actions bar appears above the scope/snapshot list. If this is causing a problem on other pages, simply modify the actions bar z-index for that page alone.
	display: flex;
	align-items: center;
	justify-content: space-between;

	.notices-section {
		line-height: 34px;
		display: flex;
		gap: 0 20px;
		align-items: center;
		.ficon-Important {
			color: #d60000;
			font-size: 54px;
			margin-top: 1px;
			margin-left: -23px;
		}
		.notices-heading {
			color: #666666;
			font-size: 16px;
		}
		.notices {
			display: flex;
		}
		.notice {
			color: #d60000;
			font-weight: 600;
			font-size: 16px;
			font-family: $fontFamilyNonPlainText;

			+ .notice {
				margin-left: 10px;
				&:before {
					content: "·";
					color: #666666;
					margin-right: 10px;
				}
			}
		}
	}

	.actions {
		display: flex;
		gap: 0px 25px;
		align-items: center;

		input {
			height: inherit;
		}
	}

	.pagination {
		display: flex;
		height: 34px;
		align-items: center;
		.pagination-info {
			margin-left: 7px;
			border-left: 1px solid #ccc;
			padding-left: 25px;
			.info-item {
				display: inline-flex;
				position: relative;
				+ .info-item {
					margin-left: 15px;
					padding-left: 15px;
				}
			}
			.info-item + .info-item:before {
				content: "·";
				position: absolute;
				left: -3px;
			}
		}
		.previous-page-button, .next-page-button {
			height: 60px;
			width: 50px;
			justify-content: center;
			display: flex;
			align-items: center;
			font-size: 14px;
			cursor: pointer;
			color: #666;
			&.disabled {
				cursor: default;
				opacity: 0.3;
			}
			&:not(.disabled) {
				&:hover {
					font-size: 22px;
					color: lighten($primaryColor, 12%);
				}
				&:active {
					font-size: 18px;
					color: lighten($primaryColor, 12%);
				}
			}
		}
		.previous-page-button {
			// padding-right: 25px;
		}
		.next-page-button {
			// padding-left: 25px;
		}
		.page-input-with-action {
			display: flex;
			.go-to-page-button-container {
				position: relative;
			}
		}
		.page-number-input {
			&, & input {
				width: 55px;
				height: 34px;
			}
			& input {
				text-align: center;
			}
		}
	}

	.ficon-BullseyeTargetEdit {
		font-size: 18px;
	}

}

.has-tooltip-indicator {
	&::before {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 1px;
		content: "";
		border-style: solid;
		border-image: repeating-linear-gradient(90deg, #666 0px, #666 4px, transparent 4px, transparent 7px) 1;
		border-width: 1px 0 0px 0px;
	}
}

.section-title.light {
	&.has-tooltip-indicator, & .has-tooltip-indicator {
		&::before {
			border-image: repeating-linear-gradient(90deg, #888 0px, #888 4px, transparent 4px, transparent 7px) 1; // Lighten the tooltip indicator color.
		}
	}
}

.related-objects-notice {
	.ficon, .notice-text {
		color: #666666;
	}
	.ficon {
		&.ficon-FullHistory {
			margin-right: 13px;
			font-size: 17px;
		}
		&.ficon-DOM {
			margin-right: 8px;
			margin-top: 1px;
			font-size: 18px;
		}
		&.ficon-Variable2 {
			margin-right: 13px;
			margin-top: 2px;
			font-size: 20px;
		}
		&.ficon-Rocket {
			margin-right: 8px;
			margin-top: 1px;
			font-size: 18px;
		}
	}
	&:hover {
		.related-objects-popup {
			display: flex;
		}
	}

	display: inline-flex;
	align-items: center;
	height: 34px;
	font-size: 16px;
	position: relative;

	.related-objects-popup {
		z-index: 1500;
		display: none;
		flex-direction: column;
		transition: all 0.2s;
		position: absolute;
		bottom: 38px;
    transform: translateX(calc(-50% + 15px));
		border-top: 3px solid rgba(255,255,255,0.25);
		border-left: 3px solid rgba(255,255,255,0.25);

		// Add an invisible rectangle to the bottom of the popup, so that the popup doesn't disappear when you move the mouse from the notice to the popup.
		&:before {
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			bottom: -11px;
			height: 11px;
		}

		// Add a triangle to the bottom of the popup.
		&:after {
			position: absolute;
			left: 50%;
			bottom: -12px;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 13px 8px 0 8px;
			border-color: #bababa transparent transparent transparent;
		}

		&.right-aligned {
			transform: translateX(calc(-100% + 52px));
			&::after {
				left: auto;
				right: 20px;
			}
		}

		.popup-title {
			font-family: $fontFamilyNonPlainText;
			font-weight: 600;
			font-size: 15px;
			min-width: max-content;
			text-transform: uppercase;
			text-align: center;
			background-color: #444;
			// background-color: #efefef;
			// border-color: #bababa;
			// border-bottom-width: 3px;
			// border-style: solid;
			color: white;
			// color: #333;
		}
		.notice-for-objects {
			background-color: #bababa;
			color: #000;
			font-weight: 400;
			font-size: 15px;
			text-align: center;
		}
		.object-rows {
			display: grid;
			grid-template-columns: [object-row-key] auto [object-row-value] auto [end];
			overflow-y: auto;
			max-height: 300px;
			border-top: 2px solid #bababa;
			border-right: 1px solid #bababa;
			border-bottom: 3px solid #bababa;
			align-items: center;
			background-color: #444;
			width: max-content;
			max-width: 300px;
			min-width: 100%;
		}
		.notice-for-objects ~ .object-rows {
			border-top: 0;
		}
		.object-row {
			display: contents;
			&.current-value {
				.object-row-value {
					background-color: #dbdbdb;
				}
			}
		}

		.popup-title, .object-row-key, .object-row-value, .notice-for-objects {
			padding-left: 11px;
			padding-right: 11px;
		}
		.popup-title, .notice-for-objects {
			line-height: 34px;
		}
		.object-row-key, .object-row-value {
			font-size: 14px;
			line-height: 24px;
			padding-top: 5px;
			padding-bottom: 5px;
		}
		.object-row-key {
			background-color: #444;
			color: white;
			text-align: right;
			grid-column-start: object-row-key;
			align-self: center;
			width: max-content;
			justify-self: right;
		}
		.object-row-value {
			grid-column-start: object-row-value;
			background-color: #efefef;
			color: #333;
			font-weight: 500;
			max-width: 300px;
			align-self: stretch;
			justify-self: stretch;
			.single-value-within-list {
				+ .single-value-within-list {
					&::before {
						content: ", ";
					}
				}
			}
		}
	}
}

.grid-based-table {
	display: grid;
	position: relative;
	column-gap: 0px;
	align-items: center;

	.row {
		display: contents;
	}

	.headings-background {
		grid-column-start: start;
		grid-column-end: end;
		grid-row-start: 1;
		display: flex;
		height: 50px;
		border-bottom: 2px solid #d9d9d9;
    background-color: white;
		&:not(.non-sticky) {
			position: sticky;
			top: 0;
			z-index: 1000;
		}
	}
	.heading {
		height: 47px;
		line-height: 47px;
		padding: 0px;
		background-color: white;
		grid-row-start: 1;
		display: flex;
		&:not(.non-sticky) {
			position: sticky;
			z-index: 1100;
			top: 0;
		}
	}
	.heading, .inner-heading {
		text-transform: uppercase;
		font-size: 16px;
		color: #888888;
		font-weight: 500;
	}
	.inner-heading {
		line-height: 34px; /* Make sure to keep this in sync with ScopeSnapshotInheritancesComponent.inheritanceDiagramConstants.headingHeight */
	}
	.row-spacer-and-hoverable {
		display: flex;
		grid-column-start: start;
		grid-column-end: end;
		border-bottom: 1px solid #e4e4e4;
	}

	&.listing-for-scope-snapshots {

		.heading {
			&.scope-version-heading {
				grid-column-start: start-of-scope-version-dropdown;
				padding-left: 0;
			}
			&.deployments-heading {
				grid-column-start: start-of-snapshot-deployments-notice;
				grid-column-end: span 2;
				padding-left: 0;
			}
			&.state-and-actions-heading {
				grid-column-start: start-of-state-and-actions;
				flex-direction: row-reverse;
			}
		}
		app-scope-snapshot-selector {
			grid-row-end: span 1;
		}

		.row {
			& > app-scope-snapshot-selector {
				.custom-select-wrapper, .snapshot-with-options-wrapper {
					display: flex;
				}
				.snapshot-with-options-wrapper {
					grid-column-start: start-of-scope-version-dropdown;
				}
			}
			.snapshot-deployments-notice {
				grid-column-start: start-of-snapshot-deployments-notice;
				padding-right: 20px;
				min-width: 70px;
			}
			& > app-scope-snapshot-selector .custom-select-wrapper, & > app-scope-snapshot-selector .snapshot-with-options-wrapper, .snapshot-deployments-notice {
				padding-top: 20px;
				padding-bottom: 20px;
			}

			&.static-scope-snapshot, &.modifiable-scope-snapshot-minimalistic {
				> app-scope-snapshot-selector {
					.scope-variables-with-options .custom-select {
						.dropdown {
							// min-width: auto;
						}
						&:not(.modified) {
							.ficon-QueryList, .ficon-ClearFilter {
								display: none;
							}
						}
					}
					.ficon.transformation-icon {
						color: #333;
					}
					.scope-variables-with-options .custom-select .secondary-label {
						// background-color: transparent;
						font-weight: 400;
						flex-wrap: wrap;
						max-width: 25vw;
						height: auto;

						.show-as-older-state {
							color: #afafaf;
						}
					}

					.scope-variables-with-options .custom-select:not(.unselected) .primary-label {
						display: none;
					}
					.selected-options.all-option-selected {
						font-style: italic;
					}

				}
			}

			&.static-scope-snapshot > app-scope-snapshot-selector {
				.scope-variables-with-options .custom-select {
					.dropdown {
						// min-width: auto;
					}
					&:not(.modified) {
						.ficon-QueryList, .ficon-ClearFilter {
							display: none;
						}
					}
				}
				.scope-variables-with-options .custom-select .secondary-label {
					// background-color: transparent;
					font-weight: 400;
					flex-wrap: wrap;
					max-width: 25vw;
					height: auto;

					.show-as-older-state {
						color: #afafaf;
					}
				}

				.scope-variables-with-options .custom-select:not(.unselected) .primary-label {
					display: none;
				}
				.selected-options.all-option-selected {
					font-style: italic;
				}

				.scope-variables-with-options .custom-select-wrapper .custom-select .secondary-label {
					background-color: transparent;
					padding-left: 0px;
				}
			}
			&.modifiable-scope-snapshot-minimalistic > app-scope-snapshot-selector {
				.scope-variables-with-options .custom-select-wrapper:not(:hover) .custom-select .secondary-label {
					background-color: #efefef;
				}
			}
			& .state-and-actions {
				grid-column-start: start-of-state-and-actions;
				display: flex;
				gap: 0px 25px;
				padding-top: 20px;
				padding-bottom: 20px;
				min-height: 34px;
				justify-content: flex-end;
				align-items: center;
				.state {
					.error-summary {
						display: flex;
						line-height: 34px;
						gap: 0 5px;
						.ficon-Important {
							font-size: 20px;
							color: $red-text-color;
						}
						.static-error-text, .action-link {
							font-size: 15px;
							font-weight: 600;
							text-transform: none;
							// text-decoration: none;
							font-family: $fontFamilyNonPlainText;
							.ficon {
								font-size: 14px;
							}
							// &::after {
							// 	border-bottom-color: $red-text-color;
							// }
						}
						.static-error-text {
							color: $red-text-color;
						}
					}
				}
			}
			& {
				app-scope-snapshot-selector .snapshot-with-options-wrapper:hover, app-scope-snapshot-selector .custom-select-wrapper:hover, app-scope-snapshot-selector .scope-variables-with-options:hover, .modifiable-inheritances .ordinal-input-holder:hover, app-scope-snapshot-selector .scope-variables-with-options:hover ~ .snapshot-with-options-wrapper {
					z-index: 10001 !important; // Make sure the dropdown is above the actions bar. We use !important to override the inline style that already exists which is meant to apply when not hovering.
				}
			}
		}
	}
}

.p-tooltip {
	z-index: 15000 !important;
	.p-tooltip-text {
		white-space: pre-wrap;
		padding: 0.5rem 0.75rem;
	}
	&.width-max-content {
		.p-tooltip-text {
			width: max-content;
		}
	}
	&.width-wider {
		.p-tooltip-text {
			width: max-content;
			max-width: 420px;
		}
	}
}
