$actionIconWidth:2rem;
$actionIconHeight:2rem;

$inputPadding: .75rem .75rem;
$inputListItemPadding: .75rem .75rem;
$inputListHeaderPadding:.75rem;
$inputGroupAddOnMinWidth:2.75rem;

$multiselectHeaderIconWidth:2.25rem;
$multiselectHeaderIconHeight:.2.25rem;

$buttonPadding:0.643rem .75rem;
$buttonIconOnlyWidth:2.75rem;
$buttonIconOnlyPadding:0.643rem;

$ratingIconFontSize:1rem;

$calendarCellDateWidth:2.25rem;
$calendarCellDateHeight:2.25rem;

$calendarActionIconWidth:2.25rem;
$calendarActionIconHeight:2.25rem;

$calendarButtonBarPadding:.75rem 0;

$panelHeaderPadding:.75rem;
$panelContentPadding:.75rem;
$panelFooterPadding:.75rem .75rem;

$accordionHeaderPadding:1.25rem;
$accordionContentPadding:.75rem 1.25rem;

$tabviewHeaderPadding:.75rem 1.25rem;
$tabviewContentPadding:.75rem;

$cardBodyPadding:.75rem;
$cardContentPadding:.75rem 0;
$cardFooterPadding:.75rem 0 0 0;
$paginatorPadding:.375rem .75rem;

$tableHeaderPadding:.75rem .75rem;
$tableHeaderCellPadding:.75rem .75rem;
$tableBodyCellPadding:.75rem .75rem;
$tableFooterCellPadding:.75rem .75rem;
$tableFooterPadding:.75rem .75rem;

$dataViewContentPadding:.75rem;
$treeNodeContentPadding:.25rem;

$messageMargin:.75rem 0;
$messagePadding: 1rem 1.25rem;
$messageIconFontSize:1.5rem;
$messageCloseIconWidth:2.25rem;
$messageCloseIconHeight:2.25rem;
$inlineMessagePadding:.75rem .75rem;

$toastPadding:1.25rem;

$toastCloseIconWidth:1.5rem;
$toastCloseIconHeight:1.5rem;

$dialogHeaderPadding:1.25rem;
$dialogContentPadding: 0 1.25rem 1.25rem 1.25rem;
$dialogFooterPadding:.75rem 1.25rem;

$dialogHeaderActionIconWidth:2.25rem;
$dialogHeaderActionIconHeight:2.25rem;

$sidebarCloseIconWidth:2.25rem;
$sidebarCloseIconHeight:2.25rem;

$overlayPanelCloseIconWidth:2.25rem;
$overlayPanelCloseIconHeight:2.25rem;

$menuitemPadding:.75rem .75rem;
$submenuHeaderPadding:.75rem;

$breadcrumbPadding:.75rem;

$horizontalMenuPadding:.75rem;

$horizontalMenuRootMenuitemPadding:.75rem;

$menubarButtonWidth:2.25rem;
$menubarButtonHeight:2.25rem;

$carouselNavIconWidth:2.25rem;
$carouselNavIconHeight:2.25rem;
